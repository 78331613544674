import React, { Component } from "react";

class OPTCRedFlagExit extends Component {
  componentDidMount() {
  }
  render() {
    return (<section className="sectionContent"><div className="page">
    <header><h2>OPTC Red Flag Exit</h2></header>  
    <div className="pageContent"></div> 
    </div></section>);
  }
}

export default OPTCRedFlagExit;
