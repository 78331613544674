import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import MarketingHomepage from "./components/misc/MarketingHomepage.js";
import SignUpRedirect from "./components/utilities/SignUpRedirect";
import TokenRefresh from "./components/misc/TokenRefresh";

import { MsalProvider } from "@azure/msal-react";
import SessionStoreManager from "./components/utilities/SessionStoreManager.js";
import { EventType, InteractionType } from "@azure/msal-browser";
import { authProvider, forgotPasswordRequest } from "./components/utilities/authProvider";
import { clientIdB2C, forgotPasswordPolicyB2C, pwdAuthorityB2C, webURL } from "./api";

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Marketing page is publicly availbale page and usual proccessing logic does not apply
const stripTrailingSlash = webURLWithSlashes => {
  return webURLWithSlashes.endsWith("/")
    ? webURLWithSlashes.slice(0, -1)
    : webURLWithSlashes;
};
var webURLWithoutSlashes = stripTrailingSlash(webURL);
//alert("detecting URL");

// Handle password reset
var msalErrorDescription = SessionStoreManager.getValueByKeyAsString(
  "msal.error.description"
);

// If user come to app as result of pwd reset or forgot pwd activity
if (msalErrorDescription) {
  if (msalErrorDescription.indexOf("AADB2C90118") >= 0) {
    SessionStoreManager.clearAllKeys();
    // Trigger Forgot password policy
    // window.location.href =
    //   "https://bdoukb2cdevief.b2clogin.com/bdoukb2cdevief.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_BDO_ForgotPassword&client_id=055d82be-6483-48bf-a2f6-616852c94394&nonce=defaultNonce&redirect_uri=http%3A%2F%2Flocalhost:3000%2Fsignupredirect&scope=openid&response_type=id_token&prompt=login&state=215e2300-38b0-4921-bf7f-2212d4a8ad66";

    window.location.href =
    pwdAuthorityB2C +
      "/oauth2/v2.0/authorize?p=" +
      forgotPasswordPolicyB2C +
      "&client_id=" +
      clientIdB2C +
      "&nonce=defaultNonce&redirect_uri=" +
      webURL +
      "signupredirect" +
      "&scope=openid&response_type=id_token&prompt=login&state=215e2300-38b0-4921-bf7f-2212d4a8ad66";
  }
}
// Set an active account

const accounts = authProvider.getAllAccounts();

  if (accounts.length == 1) {
    authProvider.setActiveAccount(accounts[0]);
  }
  if(accounts.length > 1){
    var activeAccount = accounts.find(element => element.idTokenClaims.acr == 'b2c_1a_bdo_signin');
    authProvider.setActiveAccount(activeAccount);
  }

authProvider.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    // if username isn't set grab from id token claim
    account.username = account.username ? account.username : account.idTokenClaims.email;
    authProvider.setActiveAccount(account);
  }

  if (event.eventType === EventType.LOGIN_FAILURE) {
    // If user come to app as result of pwd reset or forgot pwd activity
    if (event.error && event.error.errorMessage.indexOf("AADB2C90118") > -1) {
      if (event.interactionType === InteractionType.Redirect) {
        authProvider.loginRedirect(forgotPasswordRequest);
      } else if (event.interactionType === InteractionType.Popup) {
        authProvider.loginPopup(forgotPasswordRequest).catch(e => {
          return;
        });
      }
    }
  } else if (event.eventType === EventType.LOGIN_SUCCESS) {
    if (event.payload.idTokenClaims["acr"] === "b2c_1_reset") {
      // Tokens returned from password reset policy cannot be used for sign-in policy, must log out then sign back in
      authProvider.logout({
        account: event.payload.account
      });
    }
  }
});

if (
  window.location.origin + window.location.pathname === webURL ||
  window.location.href === webURL ||
  window.location.href.startsWith(webURLWithoutSlashes + "/?utm_source=Concep") ||
  window.location.href === webURLWithoutSlashes ||
  window.location.href.startsWith(webURLWithoutSlashes + "/signupredirect") ||
  window.location.href === webURLWithoutSlashes + "/tokenrefresh" ||
  window.parent !== window
) {
  ReactDOM.render(
    <Router>
      <Switch>
        <Route
          path="/signupredirect"
          key="signupredirect"
          component={SignUpRedirect}
        />
        <Route
          path="/tokenrefresh"
          key="tokenrefresh"
          component={TokenRefresh}
        />
        <MarketingHomepage />
      </Switch>
    </Router>,
    document.getElementById("root")
  );
} else {
  if (window.location.href.startsWith(webURLWithoutSlashes + "/?utm_source=Concep") || window.location.href.startsWith(webURLWithoutSlashes + "/?utm_source=concep"))
  {
    ReactDOM.render(
      <Router>
        <Switch>
          <Route
            path="/signupredirect"
            key="signupredirect"
            component={SignUpRedirect}
          />
          <Route
            path="/tokenrefresh"
            key="tokenrefresh"
            component={TokenRefresh}
          />
          <MarketingHomepage />
        </Switch>
      </Router>,
      document.getElementById("root")
    );
  }
  else
  {
    ReactDOM.render(
    <MsalProvider instance={authProvider}>
      <Router>
        <App />
      </Router>
    </MsalProvider>,
    document.getElementById("root")
  );
  serviceWorker.unregister();
  }
  
}
