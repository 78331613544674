import React, { Component } from "react";
import NavControlsGenerator from "../utilities/NavControlsGenerator";

class PaymentConfirmation extends Component {
  componentDidMount() {
  }
  render() {
    return (
      <section className="sectionContent">
      <div className="page">
        <header><h2>Payment Confirmation</h2></header>  
    <div className="pageContent"></div> 
        
        <NavControlsGenerator currentPageNavCode={220}></NavControlsGenerator>
      </div></section>
    );
  }
}

export default PaymentConfirmation;
