import React, { Component } from "react";
import { webURL } from "../../api";
import { withRouter } from "react-router-dom";

class SignUpRedirect extends Component {
  render() {
    //console.log("redirecting to myaccount from SignUpRedirect");
    return (window.location.href = webURL + "mytaxreturns");
    /* return (
      <>
        <div>SignUp redirect</div>
      </>
    );*/
  }
}

export default withRouter(SignUpRedirect);
