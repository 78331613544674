import React, { Component } from "react";
import NavControlsGenerator from "../utilities/NavControlsGenerator";

class VerificationConfirmation extends Component {
  componentDidMount() {
  }
  handleNextNavigation = c => {
    this.props.history.push("/mytaxreturns");
  };
  render() {
    return (
      <section className="sectionContent">
        <div className="page exitPage">
          <header>
            <h2>Thank you!</h2>
          </header>
          <div className="pageContent">
            <p>
              We are now ready for you to start your Personal Tax Return
              Questionnaire.
            </p>
          </div>

          <NavControlsGenerator currentPageNavCode={130}></NavControlsGenerator>
        </div>
      </section>
    );
  }
}
export default VerificationConfirmation;
