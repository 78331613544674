import React, { Component } from "react";
import NavControlsGenerator from "../utilities/NavControlsGenerator";

class VerificationSpinnerNotice extends Component {
  componentDidMount() {
  }
  handleNavigation = c => {
    this.props.history.push("/mytaxreturns");
  };
  render() {
    return (
      <section className="sectionContent">
        <div className="page exitPage">
          <header>
            <h2>We are working behind the scenes</h2>
          </header>
          <div className="pageContent">
            <p>
              This process can take anything from a few seconds to a few days.
            </p>
            <p>You can close your browser, your progress won’t be lost.</p>
            <p>We’ll send you an email when ready.</p>
          </div>
          <NavControlsGenerator currentPageNavCode={110}></NavControlsGenerator>
        </div>
      </section>
    );
  }
}

export default VerificationSpinnerNotice;
