import React, { Fragment } from "react";

import * as Survey from "survey-react";

import registrationSurvey from "./registrationSurvey.json";

import { endpoints, httpPOSTNotAuthenticated } from "../../api";

import Spinner from "react-bootstrap/Spinner";

import Modal from "react-bootstrap/Modal";


/**

 * This component renders a questionnaire (using the Survey js component) for the given customer along with any previously recorded responses.

 * The user can save at any pont and submit their completed survey after they have completed all mandatory questions.

 * @returns {jsx} the survey component or a loading message

 */

class NewCustomerRegistration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      questionnaire: registrationSurvey,

      isLoading: false,

      firstname: undefined,

      middlename: undefined,

      surname: undefined,

      email: undefined,

      title: undefined,

      phoneNumber: undefined,

      mobile: undefined,

      dateofbirth: undefined,

      ninumber: undefined,

      salutation: undefined,

      addressline1: undefined,

      addressline2: undefined,

      addressline3: undefined,

      town: undefined,

      county: undefined,

      postcode: undefined,

      country: undefined,

      responses: undefined,

      utr: undefined,

      isCompleted: false,

      errorMessage: undefined,

      showErrorModal: false,
    };

    this.onValueChanged = this.onValueChanged.bind(this);
    this.onTextMarkdown = this.onTextMarkdown.bind(this);
    this.onComplete = this.onComplete.bind(this);
  }

  componentDidMount() {
    this.renderMySurvey();
  }
  onTextMarkdown(survey, _options) {
    var showdown = require('showdown'); 
    
    var converter = new showdown.Converter({openLinksInNewWindow: true});
    
    var str = converter.makeHtml(_options.text);
    //remove root paragraphs <p></p>
    str = str.substring(3);
    str = str.substring(0, str.length - 4);
    //set html
    _options.html = str;
  }
 

  onValueChanged(survey, _options) {
    var firstname = this.state.firstname;

    var middlename = this.state.middlename;

    var title = this.state.title;

    var mobile = this.state.mobile;

    var dateofbirth = this.state.dateofbirth;

    var ninumber = this.state.ninumber;

    var salutation = this.state.salutation;

    var addressline1 = this.state.addressline1;

    var addressline2 = this.state.addressline2;

    var addressline3 = this.state.addressline3;

    var town = this.state.town;

    var county = this.state.county;

    var postcode = this.state.postcode;

    var country = this.state.country;

    var surname = this.state.surname;

    var email = this.state.email;

    var telephone = this.state.telephone;

    var utr = this.state.utr;

    if (_options.name === "Q_2") {
      firstname = _options.value;
    }

    if (_options.name === "Q_3") {
      surname = _options.value;
    }

    if (_options.name === "Q_8") {
      email = _options.value;
    }

    if (_options.name === "Q_6") {
      telephone = _options.value;
    }

    if (_options.name === "Q_16") {
      utr = _options.value;
    }

    if (_options.name === "Q_4") {
      middlename = _options.value;
    }

    if (_options.name === "Q_1") {
      switch (_options.value) {
        case "1":
          title = "Mr";

          break;

        case "2":
          title = "Mrs";

          break;

        case "3":
          title = "Miss";

          break;

        case "4":
          title = "Ms";

          break;

        case "5":
          title = "Dr";

          break;

        case "other":
          title = "other";

          break;

        default:
          title = "";
      }
    }

    if (_options.name === "Q_1-Comment") {
      title = _options.value;
    }

    if (_options.name === "Q_7") {
      mobile = _options.value;
    }

    if (_options.name === "Q_5") {
      dateofbirth = _options.value;
    }

    /*  if (_options.name === "Q_16"){

    ninumber = _options.value

  } */

    /*  if (_options.name === "Q_16"){

    salutation = _options.value

  } */

    if (_options.name === "Q_9") {
      addressline1 = _options.value;
    }

    if (_options.name === "Q_10") {
      addressline2 = _options.value;
    }

    if (_options.name === "Q_11") {
      addressline3 = _options.value;
    }

    if (_options.name === "Q_12") {
      town = _options.value;
    }

    if (_options.name === "Q_13") {
      county = _options.value;
    }

    if (_options.name === "Q_14") {
      postcode = _options.value;
    }

    if (_options.name === "Q_15") {
      country = _options.value;
    }

    this.setState({
      firstname: firstname,

      surname: surname,

      email: email,

      telephone: telephone,

      utr: utr,

      middlename: middlename,

      title: title,

      mobile: mobile,

      dateofbirth: dateofbirth,

      ninumber: ninumber,

      salutation: salutation,

      addressline1: addressline1,

      addressline2: addressline2,

      addressline3: addressline3,

      town: town,

      county: county,

      postcode: postcode,

      country: country,

      responses: survey.data,
    });
  }

  renderMySurvey() {
    var surveyJSON = registrationSurvey;

    this.setState({
      questionnaire: surveyJSON,
    });
  }

  //Define a callback methods on survey complete

  onComplete(survey) {
    survey.mode = 'display';
    this.setState({
      isLoading: true

    });
    try {

     

      this.upsertResponses();
      survey.isCompleted = true;
      if (this.state.isCompleted) {
        
        survey.isCompleted = true;
      } else {
        survey.mode = 'edit';
        survey.isCompleted = false;
      }

      this.setState({
        isLoading: false,
      });
    } catch (error) {
      survey.mode = 'edit';
      survey.isCompleted = false;

      this.setState({
        isLoading: false,
      });
    }
  }

  handleCloseError() {
    this.setState({
      showErrorModal: false,

      errorMessage: undefined,
    });
  }

  async upsertResponses() {
    this.setState({
      isLoading: true
    });
    var results = {
      firstname: this.state.firstname,

      lastname: this.state.surname,

      email: this.state.email,

      telephone: this.state.telephone,

      uniqueTaxpayerReference: this.state.utr,

      responsesJson: JSON.stringify(this.state.responses),

      survey: JSON.stringify(this.state.questionnaire),

      status: "New",

      cchcode: null,

      hmrcauthcode: null,

      middlename: this.state.middlename,

      title: this.state.title,

      mobile: this.state.mobile,

      dateofbirth: this.state.dateofbirth,

      ninumber: this.state.ninumber,

      salutation: this.state.salutation,

      addressline1: this.state.addressline1,

      addressline2: this.state.addressline2,

      addressline3: this.state.addressline3,

      town: this.state.town,

      county: this.state.county,

      postcode: this.state.postcode,

      country: this.state.country,
    };

    let payload = results;

    let response = await httpPOSTNotAuthenticated(
      endpoints.filter(
        (endpoint) => endpoint.name === "CreateRegistrations"
      )[0],

      payload,

      false
    );

    if (response && response.status === 200) {
      this.setState({
        isCompleted: true,
      });
    } else {
      if (response != null)
      {
      alert("Error: " + response.body);
      this.setState({
        isCompleted: false,

        showErrorModal: true,

        errorMessage: response.body,
      });
      }
      else
      {
        alert("Error: form could not be submitted at this time");
        this.setState({
          isCompleted: false,
  
          showErrorModal: true,
  
          errorMessage: "No response",
        });
      }
     
    }
  }

  render() {
    var surveyToRender = "";

    var showErrorModal = "";

    if (this.state.showerrorModal) {
      showErrorModal = (
        <div>
          <Modal
            size="md"
            centered
            show={true}
            onHide={this.handleCloseError.bind(this)}
            aria-labelledby="additionalFiles"
            className="additionalFiles"
          >
            <Modal.Header closeButton>Error</Modal.Header>

            <Modal.Body>{this.state.errorMessage}</Modal.Body>

            <Modal.Footer></Modal.Footer>
          </Modal>
        </div>
      );
    }

    if (this.state.isLoading === false) {
      if (this.state.isCompleted) {
        surveyToRender = (
          <Fragment>
            <article className="successPage">
              <section className="successMessage">
                <h2>What happens next?</h2>

                <p>
                  Thank you for submitting your completed registration form.
                </p>
                <p>
                  We aim to contact you within two working days to provide our
                  fee quote.
                </p>
                <p>
                  We’ll then ask if you are happy with our fee quote and the
                  terms of our contract and once you have confirmed this then we
                  will carry out our client take on procedures.
                </p>

                <p>
                  We’d then notify you as soon as our client take-on procedures
                  have been successfully completed and send you a link to your
                  portal where you’ll be able to complete your tax return
                  questionnaire and later see your tax return, any tax
                  information you’ve provided to us and correspondence with your
                  BDO Tax Team.
                </p>

                <p>Please <a
                      className=""
                      href="https://www.bdo.co.uk/en-gb/contact"
                      title="Contact us"
                      target="_blank"
                      rel="noopener noreferrer"
                    >contact us</a> if you have any queries.</p>

                <button
                  className="button primary getStarted"
                  onClick={this.props.handleComplete}
                >
                  Return to home page
                </button>
              </section>
            </article>
          </Fragment>
        );
      } else {
        if (this.state.isLoading === true) { 
          surveyToRender = (
            <Fragment>
              <div className="spinnerModal">
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            </Fragment>
          );
        }
else
{

        //Create the model and pass it into react Survey component

        //You may create survey model outside the render function and use it in your App or component

        //The most model properties are reactive, on their change the component will change UI when needed.

        var model = new Survey.Model(this.state.questionnaire);

        Survey.StylesManager.applyTheme("bootstrap");

        var myCss = {
          matrix: {
            root: "table table-striped",
          },

          navigationButton: "button btn-lg",
        };

        window.survey = new Survey.Model(this.surveyJSON);
     

        surveyToRender = (
          <Survey.Survey
          onTextMarkdown={this.onTextMarkdown}
            model={model}
            css={myCss}
            onComplete={this.onComplete}
            onValueChanged={this.onValueChanged}
            showQuestionNumbers="off"
          />
        );
      }
    }
    } else {
      surveyToRender = (
        <Fragment>
          <div className="spinnerModal">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        </Fragment>
      );
    }

    return (
      <Fragment>
        {surveyToRender}
      </Fragment>
    );
  }
}

export default NewCustomerRegistration;
