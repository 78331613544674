import React, { Component } from "react";
import NavControlsGenerator from "../utilities/NavControlsGenerator";

class AwaitingChangeNotice extends Component {
  componentDidMount() {
  }
  render() {
    return (
      <section className="sectionContent">
      <div className="page">
        <header>
          <h2>Awaiting Change Notice</h2>
        </header>
        <div className="pageContent"></div>
        <NavControlsGenerator currentPageNavCode={180}></NavControlsGenerator>
      </div></section>
    );
  }
}

export default AwaitingChangeNotice;
