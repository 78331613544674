import React, { Component, Fragment } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { endpoints, httpGET, httpPUT, appInsightsCode } from "../../api";
import Constants from "../utilities/Constants";
import SessionStoreManager from "../utilities/SessionStoreManager";
import Auth from "../../Auth";
import TaxReturnApproval from "../taxreturn/TaxReturnApproval.js";
import HistoricalTaxYears from "../taxreturn/HistoricalTaxYears.js";
import ProvideFurtherInformation from "../misc/ProvideFurtherInformation";

class MyTaxReturns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAdditionalDocumentsForm: false,
      accountSetup: "notStarted",
      questionnaireCompleted: "notStarted",
      isLinkVisible: "hidden",
      preparingTaxReturn: "notStarted",
      reviewTaxReturn: "notStarted",
      approvedForSubmission: "notStarted",
      submitedToHMRC: "notStarted",
      navNode: 60,
      taxYear: "",
      step1Text: "",
      step2Text: "",
      step3Text: "",
      step4Text: "",
      step5Text: "",
      step6Text: "",
      step1Title: "",
      step2Title: "",
      step3Title: "",
      step4Title: "",
      step5Title: "",
      step6Title: "",
      viewQuestionnaireButtonText: "",
      readOnlyTaxYearId: "",
      readOnlyTaxYearProcess: "",
      linkVersion: "",
      showAccount: true,
      taxYearId: "",
      reviewStatus: "",
      allTaxReturnProcesses: undefined,
      additionalFiles: [],
      showFurtherInformation: false,
    };
    this.appInsightsCode = appInsightsCode;
    this.handleViewReadOnly = this.handleViewReadOnly.bind(this);
    this.hideProvideFurtherInformation = this.hideProvideFurtherInformation.bind(this);
  }
  componentDidMount() {
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: appInsightsCode,
      },
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
    const auth = new Auth();
    if (
      SessionStoreManager.getValueByKey(Constants.currentUserCosmosDBKey) &&
      SessionStoreManager.getValueByKey(Constants.navRulesKey) &&
      SessionStoreManager.getValueByKey(Constants.navNodesKey) &&
      SessionStoreManager.getValueByKey(Constants.taxYearProcessKey)
    ) {
      this.getLatestTaxYearProcess(
        auth,
        SessionStoreManager.getValueByKey(Constants.taxYearProcessKey).taxYearId
      );
      this.LoadFilterQuestionTiles(auth);
      this.GetStatusFromTaxReturn(auth);
      this.getTaxYearName(auth);
      this.getAllTaxReturnProcesses(auth);
      this.getAllAdditionalFiles(auth);
    }
  }
  async getAllAdditionalFiles(auth) {
    
    var currentUser = SessionStoreManager.getValueByKey(
      Constants.currentUserCosmosDBKey
    );
    var parameters =
      currentUser.id +
      "/" +
      SessionStoreManager.getValueByKey(Constants.taxYearProcessKey).taxYearId;

    let response = await httpGET(
      endpoints.filter(
        (endpoint) => endpoint.name === "GetAdditionalDocuments"
      )[0],
      parameters,
      await auth.getToken(),
      true
    );
        
    if (response && response.status === 200) {
      this.setState({
        additionalFiles: response.body,
      });
    }
  }
  async getAllTaxReturnProcesses() {
    const auth = new Auth();
    var currentUser = SessionStoreManager.getValueByKey(
      Constants.currentUserCosmosDBKey
    );
    var parameters =
      currentUser.id +
      "/" +
      false +
      "/" +
      SessionStoreManager.getValueByKey(Constants.taxYearProcessKey).taxYearId;

    let response = await httpGET(
      endpoints.filter((endpoint) => endpoint.name === "GetTaxYearProcess")[0],
      parameters,
      await auth.getToken(),
      true
    );

    if (response && response.status === 200) {
      this.setState({
        allTaxReturnProcesses: response.body,
      });
    }
  }
  handleReturnToAccount() {
    alert("close");
    this.setState({
      readOnlyTaxYearId: "",
      readOnlyTaxYearProcess: "",
      showAccount: true,
    });
  }
  handleMoreInfo() {
    this.setState({
      showAdditionalDocumentsForm: false,
      showFurtherInformation:true
    });
  }
  hideProvideFurtherInformation(){
    this.setState({
      showFurtherInformation:false,
    });
  }
  handleViewAdditionalFiles() {
    this.setState({
      showAdditionalDocumentsForm: true,
    });
  }
  handleCloseAdditionalFiles() {
    this.setState({
      showAdditionalDocumentsForm: false,
    });
  }

  async handleViewReadOnly(taxYearId) {

    const auth = new Auth();
    var currentUser = SessionStoreManager.getValueByKey(
      Constants.currentUserCosmosDBKey
    );
    var parameters = currentUser.id + "/" + taxYearId;

    let response = await httpGET(
      endpoints.filter((endpoint) => endpoint.name === "GetTaxYearProcess")[0],
      parameters,
      await auth.getToken(),
      true
    );

    if (response && response.status === 200) {
      this.setState({
        showAccount: false,
        readOnlyTaxYearId: taxYearId,
        readOnlyTaxYearProcess: response.body,
      });
    }
  }
  async getLatestTaxYearProcess(auth, taxYearId) {
    
    //ensure that we are using the latest version of the tax year process
    var currentUser = SessionStoreManager.getValueByKey(
      Constants.currentUserCosmosDBKey
    );
    var parameters = currentUser.id + "/" + taxYearId;
      
    let response = await httpGET(
      endpoints.filter((endpoint) => endpoint.name === "GetTaxYearProcess")[0],
      parameters,
      await auth.getToken(),
      true
    );

    if (response && response.status === 200) {
      if(response.body.reviewStatus==null || typeof(response.body.reviewStatus)=='undefined'){response.body.reviewStatus='';}
        if(response.body.pathToTaxReturnBlob==null || typeof(response.body.pathToTaxReturnBlob)=='undefined'){response.body.pathToTaxReturnBlob='';}
        if(response.body.pathToTransmittalLetterBlob==null || typeof(response.body.pathToTransmittalLetterBlob)=='undefined'){response.body.pathToTransmittalLetterBlob='';}
        if(response.body.pathToPaymentBlob==null || typeof(response.body.pathToPaymentBlob)=='undefined'){response.body.pathToPaymentBlob='';}
        if(response.body.pathToSmartSearchBlob==null || typeof(response.body.pathToSmartSearchBlob)=='undefined'){response.body.pathToSmartSearchBlob='';}
        if(response.body.ctoStatus==null || typeof(response.body.ctoStatus)=='undefined'){response.body.ctoStatus='';}
        if(response.body.questionnaireStarted==null || typeof(response.body.questionnaireStarted)=='undefined'){response.body.questionnaireStarted='';}
        if(response.body.hmrcSubmissionReference==null || typeof(response.body.hmrcSubmissionReference)=='undefined'){response.body.hmrcSubmissionReference='';}
        if(response.body.hmrcSubmissionDate==null || typeof(response.body.hmrcSubmissionDate)=='undefined'){response.body.hmrcSubmissionDate='';}
        if(response.body.rpaComments==null || typeof(response.body.rpaComments)=='undefined'){response.body.rpaComments='';} 
       
      SessionStoreManager.setValueForKey(
        Constants.taxYearProcessKey,
        response.body
      );
    }
  }
  async GetStatusFromTaxReturn(auth) {
    var currentTaxProcess = SessionStoreManager.getValueByKey(
      Constants.taxYearProcessKey
    );
    var currentUser = SessionStoreManager.getValueByKey(
      Constants.currentUserCosmosDBKey
    );

    var parameters = currentUser.id + "/" + currentTaxProcess.taxYearId;

    let response = await httpGET(
      endpoints.filter(
        (endpoint) =>
          endpoint.name === "GetTaxReturnStatusByCustIdTaxYearAndQuestVersion"
      )[0],
      parameters,
      await auth.getToken(),
      true
    );

    if (response && response.status === 200) {
      response.body.forEach((e) => {
        this.GetStatusName(auth, e.status);
      });
    }
  }
  async getTaxYearName(auth) {
    var currentTaxProcess = SessionStoreManager.getValueByKey(
      Constants.taxYearProcessKey
    );

    var parametersAttachment = currentTaxProcess.taxYearId;
    let response = await httpGET(
      endpoints.filter((endpoint) => endpoint.name === "GetTaxYear")[0],
      parametersAttachment,
      await auth.getToken(),
      true
    );

    if (response && response.status === 200) {
      this.setState({
        taxYear:
          response.body[0].fromYear +
          "/" +
          response.body[0].toYear.toString().slice(-2),
        taxYearId: currentTaxProcess.taxYearId,
      });
    }
  }
  async GetStatusName(auth, status) {
    var currentTaxProcess = SessionStoreManager.getValueByKey(
      Constants.taxYearProcessKey
    );
    var reviewStatus = currentTaxProcess.reviewStatus;
    let response = await httpGET(
      endpoints.filter(
        (endpoint) => endpoint.name === "GetStatusNameByStatusID"
      )[0],
      status,
      await auth.getToken(),
      true
    );

    if (response && response.status === 200) {
      response.body.forEach((e) => {
        switch (e.title) {
          case "Account Setup":
            this.setState({
              accountSetup: "complete",
              questionnaireCompleted: "notStarted",
              isLinkVisible: "none",
              preparingTaxReturn: "notStarted",
              reviewTaxReturn: "notStarted",
              approvedForSubmission: "notStarted",
              submitedToHMRC: "notStarted",
              viewQuestionnaireButtonText: "",
              step1Text: "Your account was successfully created",
              step2Text: "",
              step3Text: "",
              step4Text: "Click on the icon to view the content of the document",
              step5Text: "",
              step6Text: "",
              step1Title: "Account created",
              step2Title: "Your questionnaire",
              step3Title: "Preparing your tax return",
              step4Title: "Please review your tax return",
              step5Title: "You have approved your tax return",
              step6Title: "We will submit your tax return to HMRC",
              linkVersion: "current",
              reviewStatus: "",
            });
            break;
          case "Personal Tax Questionnaire":
            this.setState({
              accountSetup: "complete",
              questionnaireCompleted: "inProgress",
              isLinkVisible: "",
              preparingTaxReturn: "notStarted",
              reviewTaxReturn: "notStarted",
              approvedForSubmission: "notStarted",
              submitedToHMRC: "notStarted",
              viewQuestionnaireButtonText: "Continue",
              step1Text: "Your account was successfully created",
              step2Text: "In progress",
              step3Text: "",
              step4Text: "Click on the icon to view the content of the document",
              step5Text: "",
              step6Text: "",
              linkVersion: "current",
              reviewStatus: "",
              step1Title: "Account created",
              step2Title: "Your questionnaire",
              step3Title: "Preparing your tax return",
              step4Title: "Please review your tax return",
              step5Title: "You have approved your tax return",
              step6Title: "We will submit your tax return to HMRC",
            });
            break;
          case "Preparing Tax Return":
            if (reviewStatus === "Rejected") {
              this.setState({
                accountSetup: "complete",
                questionnaireCompleted: "complete",
                isLinkVisible: "",
                preparingTaxReturn: "complete",
                reviewTaxReturn: "inProgress",
                approvedForSubmission: "notStarted",
                submitedToHMRC: "notStarted",
                viewQuestionnaireButtonText: "View questionnaire",
                step1Text: "Your account was successfully created",
                step2Text: "Complete",
                step3Text: "Complete",
                step4Text: "Click on the icon to view the content of the document",
                step5Text: "",
                step6Text: "",
                linkVersion: "complete",
                reviewStatus: "Rejected",
                step1Title: "Account created",
                step2Title: "Your questionnaire",
                step3Title: "Preparing your tax return",
                step4Title: "Query submitted",
                step5Title: "You have approved your tax return",
                step6Title: "We will submit your tax return to HMRC",
              });
            } else {
              this.setState({
                accountSetup: "complete",
                questionnaireCompleted: "complete",
                isLinkVisible: "",
                preparingTaxReturn: "inProgress",
                reviewTaxReturn: "notStarted",
                approvedForSubmission: "notStarted",
                submitedToHMRC: "notStarted",
                viewQuestionnaireButtonText: "View questionnaire",
                step1Text: "Your account was successfully created",
                step2Text: "Complete",
                step3Text: "In progress",
                step4Text: "Click on the icon to view the content of the document",
                step5Text: "",
                step6Text: "",
                linkVersion: "complete",
                reviewStatus: "",
                step1Title: "Account created",
                step2Title: "Your questionnaire",
                step3Title: "Preparing your tax return",
                step4Title: "Please review your tax return",
                step5Title: "You have approved your tax return",
                step6Title: "We will submit your tax return to HMRC",
              });
            }

            break;

          case "Review Tax Return":
            this.setState({
              accountSetup: "complete",
              questionnaireCompleted: "complete",
              isLinkVisible: "",
              preparingTaxReturn: "complete",
              reviewTaxReturn: "inProgress",
              approvedForSubmission: "notStarted",
              submitedToHMRC: "notStarted",
              viewQuestionnaireButtonText: "View questionnaire",
              step1Text: "Your account was successfully created",
              step2Text: "Complete",
              step3Text: "Complete",
              step4Text: "Click on the icon to view the content of the document",
              step5Text: "",
              step6Text: "",
              linkVersion: "complete",
              reviewStatus: "",
              step1Title: "Account created",
              step2Title: "Your questionnaire",
              step3Title: "Preparing your tax return",
              step4Title: "Please review your tax return",
              step5Title: "You have approved your tax return",
              step6Title: "We will submit your tax return to HMRC",
            });
            break;
          case "Approved for submission":
            this.setState({
              accountSetup: "complete",
              questionnaireCompleted: "complete",
              isLinkVisible: "",
              preparingTaxReturn: "complete",
              reviewTaxReturn: "complete",
              approvedForSubmission: "complete",
              submitedToHMRC: "inProgress",
              viewQuestionnaireButtonText: "View questionnaire",
              step1Text: "Your account was successfully created",
              step2Text: "Complete",
              step3Text: "Complete",
              step4Text: "Click on the icon to view the content of the document",
              step5Text: "",
              step6Text: "",
              linkVersion: "complete",
              reviewStatus: "Approved",
              step1Title: "Account created",
              step2Title: "Your questionnaire",
              step3Title: "Preparing your tax return",
              step4Title: "Please review your tax return",
              step5Title: "You have approved your tax return",
              step6Title: "We will submit your tax return to HMRC",
            });
            break;
          case "Submission to HMRC":
            this.setState({
              accountSetup: "complete",
              questionnaireCompleted: "complete",
              isLinkVisible: "",
              preparingTaxReturn: "complete",
              reviewTaxReturn: "complete",
              approvedForSubmission: "complete",
              submitedToHMRC: "complete",
              viewQuestionnaireButtonText: "View questionnaire",
              step1Text: "Your account was successfully created",
              step2Text: "Complete",
              step3Text: "Complete",
              step4Text: "Click on the icon to view the content of the document",
              step5Text: "",
              step6Text: "",
              linkVersion: "complete",
              reviewStatus: "Approved",
              step1Title: "Account created",
              step2Title: "Your questionnaire",
              step3Title: "Preparing your tax return",
              step4Title: "Please review your tax return",
              step5Title: "You have approved your tax return",
              step6Title: "We will submit your tax return to HMRC",
            });
            break;
          default:
            this.setState({
              accountSetup: "notStarted",
              questionnaireCompleted: "notStarted",
              isLinkVisible: "",
              preparingTaxReturn: "notStarted",
              reviewTaxReturn: "notStarted",
              approvedForSubmission: "notStarted",
              submitedToHMRC: "notStarted",
              viewQuestionnaireButtonText: "",
              step1Text: "",
              step2Text: "",
              step3Text: "",
              step4Text: "",
              step5Text: "",
              step6Text: "",
              linkVersion: "",
              reviewStatus: "",
              step1Title: "",
              step2Title: "",
              step3Title: "",
              step4Title: "",
              step5Title: "",
              step6Title: "",
            });
            break;
        }
      });
    }
  }

  //Methods to send the user to Questionnaire
  async updateNavNode(NavNode) {
    const auth = new Auth();

    let taxYearId = SessionStoreManager.getValueByKey(
      Constants.taxYearProcessKey
    ).taxYearId;

    await this.getTaxYearProcess(auth, taxYearId);

    let taxYearProcess = SessionStoreManager.getValueByKey(
      Constants.taxYearProcessKey
    );

    taxYearProcess.currentNavNodeCode = NavNode;

    await this.UpdateTaxYearProccess(auth, taxYearProcess);

    window.location.reload();
  }

  async getTaxYearProcess(auth, taxYearId) {
    var currentUser = SessionStoreManager.getValueByKey(
      Constants.currentUserCosmosDBKey
    );
    var parameters = currentUser.id + "/" + taxYearId;

    let response = await httpGET(
      endpoints.filter((endpoint) => endpoint.name === "GetTaxYearProcess")[0],
      parameters,
      await auth.getToken(),
      true
    );

    if (response && response.status === 200) {
      SessionStoreManager.setValueForKey(
        Constants.taxYearProcessKey,
        response.body
      );
    }
  }

  async UpdateTaxYearProccess(auth, currentTaxProcess) {
    let response = await httpPUT(
      endpoints.filter(
        (endpoint) => endpoint.name === "GetOrCreateTaxYearProcess"
      )[0],
      currentTaxProcess.id,
      await auth.getToken(),
      currentTaxProcess
    );
    if (response && response.status === 200) {
      SessionStoreManager.setValueForKey(
        Constants.taxYearProcessKey,
        JSON.parse(response.body)
      );
    }
  }

  //Load the filter question tiles response to get the state of them (IsCompleted true or false)
  async LoadFilterQuestionTiles(auth) {
    var fqTileIdArr = [];
    var currentTaxProcess = SessionStoreManager.getValueByKey(
      Constants.taxYearProcessKey
    );
    var currentUser = SessionStoreManager.getValueByKey(
      Constants.currentUserCosmosDBKey
    );

    var parameters = currentTaxProcess.taxYearId + "/" + currentUser.id;

    let response = await httpGET(
      endpoints.filter(
        (endpoint) =>
          endpoint.name ===
          "GetFilterQuestionTileResponseByTaxYearIdAndCustomerId"
      )[0],
      parameters,
      await auth.getToken(),
      true
    );

    if (response && response.status === 200) {
      var isCompleted = response.body.find((r) => r.isComplete);
      if (isCompleted) {
        if (isCompleted.isComplete === true) {
          this.setState({
            navNode: 200,
          });
        } else {
          this.setState({
            navNode: 60,
          });
        }
      } else {
        this.setState({
          navNode: 60,
        });
      }
    }
  }

  render() { 
    var status = SessionStoreManager.getValueByKey(Constants.taxYearProcessKey)
      .status;
    var historical = "";
    if (this.state.allTaxReturnProcesses) {
      historical = this.state.allTaxReturnProcesses.map((value, index) => {
        return (
          <HistoricalTaxYears
            handleViewReadOnly={this.handleViewReadOnly}
            taxYearId={value.taxYearId}
            key={value.taxYearId}
            CurrentTaxYearText=""
          />
        );
      });
    }
    var filestoshow = "";
    if (this.state.additionalFiles && this.state.additionalFiles.length>0) {
      filestoshow = JSON.parse(this.state.additionalFiles).map((value, index) => {
        return (
          <div className="description">
            <a
              key={value.FileName}
              title={value.FileName}
              target="_blank"
              rel="noopener noreferrer"
              className="fileDownload"
              href={value.FileLink}
            >
              {value.FileName}
            </a>
            <div className="date">
              <span>Last modified</span>
              {value.LastModified}
            </div>
          </div>
        );  
      });
    }
    var additionalFilesModal = "";

    if (this.state.showAdditionalDocumentsForm) { 
      additionalFilesModal = (
        <Fragment>
          <Modal
            size="md"
            centered
            show={true}
            onHide={this.handleCloseAdditionalFiles.bind(this)}
            aria-labelledby="AdditionalFiles"
            className="additionalFiles"
          >
            <Modal.Header closeButton>Additional Files</Modal.Header>
            <Modal.Body>{filestoshow}</Modal.Body>
            <Modal.Footer>
              <Button
                className="button"
                onClick={this.handleMoreInfo.bind(this)}
              >
                Add New
              </Button>
            </Modal.Footer>
          </Modal>
        </Fragment>
      );
    }
    var questionnaireLink = "";
    var provideFurtherInformation = "";
    if(this.state.showFurtherInformation){
      provideFurtherInformation = (
        <>
          <ProvideFurtherInformation
            hideProvideFurtherInformation = {this.hideProvideFurtherInformation}          
          />
        </>

      )
    }
    if (this.state.linkVersion === "current") {
      questionnaireLink = (
        <a
          className="button"
          href="#"
        ><Link className="viewTaxReturn" to={`/yourtaxinfo`}>          {" "}
        {this.state.viewQuestionnaireButtonText}{" "}</Link>

        </a>
      );
    }
    if (this.state.linkVersion === "complete") { 
      if (this.state.additionalFiles.length > 0) {
        questionnaireLink = (
          <Fragment>
            <section className="viewQuestionnaire">
              <button
                className="button"
              ><Link className="viewTaxReturn" to={`/viewtaxreturn`}>{this.state.viewQuestionnaireButtonText}</Link>
                
              </button>
            </section>

            <p className="whatsHappening">
              If you need to share additional files, please upload the files and
              supply explanatory information{" "}
              <Link onClick={() => this.handleMoreInfo(this.state.taxYearId)}>
                here
              </Link>
              .
            </p>

            <section className="fileRepository">
              <Link
                onClick={() => this.handleViewAdditionalFiles()}
                className="button"
              >
                View additional files
              </Link>
            </section>
          </Fragment>
        );
      } else {
        questionnaireLink = (
          <Fragment>
            <section className="viewQuestionnaire">
              <button
                className="button"
              >
                <Link className="viewTaxReturn" to={`/viewtaxreturn`}>{this.state.viewQuestionnaireButtonText}</Link>
              </button>
            </section>

            <p className="whatsHappening noMargin">
              If you need to share additional files, please upload the files and
              supply explanatory information{" "}
              <Link onClick={() => this.handleMoreInfo(this.state.taxYearId)}>
                here
              </Link>
              .
            </p>
          </Fragment>
        );
      }
    }
    var secondClassNameAccount =
      "cd-timeline__block " + this.state.accountSetup;
    var secondClassNamequestionnaireCompleted =
      "cd-timeline__block " + this.state.questionnaireCompleted;
    var secondClassNamepreparingTaxReturn =
      "cd-timeline__block " + this.state.preparingTaxReturn;
    var secondClassNamereviewTaxReturn =
      "cd-timeline__block " + this.state.reviewTaxReturn;
    var secondClassNameapprovedForSubmission =
      "cd-timeline__block " + this.state.approvedForSubmission;
    var secondClassNamesubmitedToHMRC =
      "cd-timeline__block " + this.state.submitedToHMRC;
    var displayReview = "";

    //reviewTaxReturn
    if (
      this.state.reviewTaxReturn === "inProgress" ||
      this.state.reviewTaxReturn === "complete" ||
      this.state.reviewStatus === "Rejected"
    ) { 
      displayReview = <TaxReturnApproval />;
    }

    var displayContents = "";
    if (this.state.showAccount) {
      if (status === "70" || status === "80") {
        if (status === "70"){
          displayContents = (
            <section className="sectionContent">
              <div className="page myAccount">
                <header className="myTaxReturns">
                  <h2>Your tax returns</h2>
                </header>
                <div className="pageContent">
                  <HistoricalTaxYears
                    key={
                      SessionStoreManager.getValueByKey(
                        Constants.taxYearProcessKey
                      ).taxYearId
                    }
                    handleViewReadOnly={this.handleViewReadOnly}
                    taxYearId={
                      SessionStoreManager.getValueByKey(
                        Constants.taxYearProcessKey
                      ).taxYearId
                    }
                    CurrentTaxYearText="Your tax return has been submitted to HMRC"
                    openAdditionalFiles={this.handleViewAdditionalFiles}
                  />
                  {historical}
                </div>
              </div>
            </section>
          );
        }
        else{
          displayContents = (
            <section className="sectionContent">
              <div className="page myAccount">
                <header className="myTaxReturns">
                  <h2>Your tax returns</h2>
                </header>
                <div className="pageContent">
                  <HistoricalTaxYears
                    key={
                      SessionStoreManager.getValueByKey(
                        Constants.taxYearProcessKey
                      ).taxYearId
                    }
                    handleViewReadOnly={this.handleViewReadOnly}
                    taxYearId={
                      SessionStoreManager.getValueByKey(
                        Constants.taxYearProcessKey
                      ).taxYearId
                    }
                    CurrentTaxYearText="Tax return process closed"
                    openAdditionalFiles={this.handleViewAdditionalFiles}
                  />
                  {historical}
                </div>
              </div>
            </section>
          );
        }
        
      } else {
        displayContents = (
          <section className="sectionContent">
            <div className="page myAccount">
              <header className="myTaxReturns">
                <h2>Your tax returns</h2>
              </header>
              <div className="pageContent">
                <div id="2020" className="taxYearProgress cd-timeline">
                  <header className="taxYearHeader">
                    <h2>{this.state.taxYear}</h2>
                  </header>
                  <div className="container max-width-lg cd-timeline__container">
                    <div className={secondClassNameAccount}>
                      <div className="cd-timeline__img"></div>
                      <div className="cd-timeline__content text-component">
                        <h2>{this.state.step1Title}</h2>
                        <p className="whatsHappening">{this.state.step1Text}</p>
                        <div className="flex justify-between items-center">
                          {" "}
                          <span className="cd-timeline__date"></span>
                        </div>
                      </div>
                    </div>
                    <div className={secondClassNamequestionnaireCompleted}>
                      <div className="cd-timeline__img"></div>
                      <div className="cd-timeline__content text-component">
                        <h2>{this.state.step2Title}</h2>
                        <p className="whatsHappening">{this.state.step2Text}</p>
                        <div className="flex justify-between items-center">
                          {" "}
                          <span className="cd-timeline__date"></span>{" "}
                          <div
                            style={{ display: this.state.isLinkVisible }}
                            className="status TRQ"
                          >
                            {questionnaireLink}
                            {provideFurtherInformation}
                          </div>{" "}
                        </div>
                      </div>
                    </div>
                    <div className={secondClassNamepreparingTaxReturn}>
                      <div className="cd-timeline__img"> </div>
                      <div className="cd-timeline__content text-component cd-timeline__content--bounce-in">
                        <h2>{this.state.step3Title}</h2>
                        <p className="whatsHappening">{this.state.step3Text}</p>
                        <div className="flex justify-between items-center">
                          {" "}
                          <span className="cd-timeline__date"></span>
                        </div>
                      </div>
                    </div>
                    <div className={secondClassNamereviewTaxReturn}>
                      <div className="cd-timeline__img"> </div>
                      <div className="cd-timeline__content text-component cd-timeline__content--bounce-in">
                        <h2>{this.state.step4Title}</h2>
                        <p className="whatsHappening">{this.state.step4Text}</p>
                        {displayReview}
                        <div className="flex justify-between items-center">
                          {" "}
                          <span className="cd-timeline__date"></span>
                        </div>
                      </div>
                    </div>
                    <div className={secondClassNameapprovedForSubmission}>
                      <div className="cd-timeline__img"></div>
                      <div className="cd-timeline__content text-component cd-timeline__content--bounce-in">
                        <h2>{this.state.step5Title}</h2>
                        <p className="whatsHappening">{this.state.step5Text}</p>
                        <div className="flex justify-between items-center">
                          {" "}
                          <span className="cd-timeline__date"></span>
                        </div>
                      </div>
                    </div>
                    <div className={secondClassNamesubmitedToHMRC}>
                      <div className="cd-timeline__img"> </div>
                      <div className="cd-timeline__content text-component cd-timeline__content--bounce-in">
                        <h2>{this.state.step6Title}</h2>
                        <p className="whatsHappening">{this.state.step6Text}</p>
                        <div className="flex justify-between items-center">
                          {" "}
                          <span className="cd-timeline__date"></span>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {historical}
              {additionalFilesModal}
            </div>
          </section>
        );
      }
    } 

    return <Fragment>{displayContents}</Fragment>;
  }
}

export default MyTaxReturns;
