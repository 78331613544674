export const baseURL = process.env.REACT_APP_API_BASE_URL || null;
export const webURL = process.env.REACT_APP_WEB_BASE_URL || null;
export const pdfEndpointUrl = process.env.REACT_APP_PDF_ENDPOINT || null;
export const signInPolicyB2C = process.env.REACT_APP_SIGN_IN_POLICY || null;
export const forgotPasswordPolicyB2C = process.env.REACT_APP_PWD_FORGOT_POLICY || null;
export const resetPasswordPolicyB2C = process.env.REACT_APP_PWD_RESET_POLICY || null;
export const apiHost = process.env.REACT_APP_API_HOST || null;
export const authorityB2C = process.env.REACT_APP_B2C_AUTHORITY || null;
export const pwdAuthorityB2C = process.env.REACT_APP_B2C_PWD_AUTHORITY || null;
export const clientIdB2C = process.env.REACT_APP_B2C_CLIENTID || null;
export const scopesB2C = process.env.REACT_APP_B2C_SCOPES || null;
export const gaCode = process.env.REACT_APP_GOOGLE_ANALYTICS_CODE || null;
export const acceptedFileTypes = process.env.REACT_APP_ACCEPTED_FILE_TYPES || null;
export const appInsightsCode = process.env.REACT_APP_APPLICATION_INSIGHTS_CODE || null;
export const saveFrequency = process.env.REACT_APP_AUTO_SAVE_FREQUENCY || null;
export const unsupportedBrowsers = process.env.REACT_APP_UNSUPPORTED_BROWSERS || null;
export const ocrDocumentsSurveyTag = process.env.REACT_APP_OCR_DOCUMENT_SURVEYJS_TAG || null;
export const ocrDocumentTargetFolder = process.env.REACT_APP_OCR_DOCUMENT_TARGET_FOLDER || null;
export const investmentsOcrDocumentsSurveyTag = process.env.REACT_APP_INVESTMENT_OCR_DOCUMENT_SURVEYJS_TAG || null;
export const investmentsOcrDocumentTargetFolder = process.env.REACT_APP_INVESTMENT_OCR_DOCUMENT_TARGET_FOLDER || null;
export const includeMedical = process.env.REACT_APP_INCLUDE_MEDICAL || null;
//export const baseURL = "http://localhost:64855/";
export const endpoints = [
  {
    name: "UpsertResponse",
    endpoint: "api/Response",
    options: {
      headers: {
        "content-type": "application/json",
        Host: apiHost
      }
    }
  },
  {
    name: "GetResponse",
    endpoint: "api/Response",
    options: {
      headers: {
        "content-type": "application/json"
      }
    }
  },
  {
    name: "GetQuestionnaire",
    endpoint: "api/Questionnaire",
    options: {
      headers: {
        "content-type": "application/json"
      }
    }
  },
  {
    name: "UpdateCustomer",
    endpoint: "api/customer",
    options: {
      headers: {
        "content-type": "application/json"
      }
    }
  },
  {
    name: "GetCustomer",
    endpoint: "api/customer",
    options: {
      headers: {
        "content-type": "application/json"
      }
    }
  },
  {
    name: "GetCurrentTaxYear",
    endpoint: "api/settings",
    options: {
      headers: {
        "content-type": "application/json"
      }
    }
  },
  {
    name: "GetOrCreateTaxYearProcess",
    endpoint: "api/TaxReturnProcess",
    options: {
      headers: {
        "content-type": "application/json"
      }
    }
  },
  {
    name: "GetTaxYearProcess",
    endpoint: "api/TaxReturnProcess",
    options: {
      headers: {
        "content-type": "application/json"
      }
    }
  },
  {
    name: "GetAllNavigationNodes",
    endpoint: "api/NavigationNode",
    options: {
      headers: {
        "content-type": "application/json"
      }
    }
  },
  {
    name: "GetAllNavigationRulesByUserType",
    endpoint: "api/NavigationRule",
    options: {
      headers: {
        "content-type": "application/json"
      }
    }
  },
  {
    name: "GetFilterQuestionTileResponseByTaxYearIdAndCustomerId",
    endpoint: "api/FilterQuestionTileResponse",
    options: {
      headers: {
        "content-type": "application/json"
      }
    }
  },
  {
    name:
      "GetIncomeSourcesToQuestionnaireByFilterQuestionTileIdTaxYearIdAndquestionnaireVersion",
    endpoint: "api/IncomeSourcesToQuestionnaire",
    options: {
      headers: {
        "content-type": "application/json"
      }
    }
  },
  {
    name:
      "GetIncomeSourcesToQuestionnaireByFilterQuestionTileIdTaxYearIdAndquestionnaireVersionSilverTier",
    endpoint: "api/IncomeSourcesToQuestionnaire",
    options: {
      headers: {
        "content-type": "application/json"
      }
    }
  },
  {
    name: "GetFilterQuestionTileByTaxYearIdAndQuestionnaireVersion",
    endpoint: "api/FilterQuestionTile",
    options: {
      headers: {
        "content-type": "application/json"
      }
    }
  },
  {
    name: "GetFilterQuestionTileQuestionnaireVersionByFilterQuestionTileId",
    endpoint: "api/FilterQuestionTile",
    options: {
      headers: {
        "content-type": "application/json"
      }
    }
  },
  {
    name: "GetFilterQuestionById",
    endpoint: "api/FilterQuestion",
    options: {
      headers: {
        "content-type": "application/json"
      }
    }
  },
  {
    name: "UpsertFilterQuestionResponse",
    endpoint: "api/FilterQuestionResponse",
    options: {
      headers: {
        "content-type": "application/json"
      }
    }
  },
  {
    name:
      "GetFilterQuestionTileResponseByFilterQuestionTileIdTaxYearIdAndCustomerId",
    endpoint: "api/FilterQuestionResponse",
    options: {
      headers: {
        "content-type": "application/json"
      }
    }
  },
  {
    name: "GetFilterQuestionResponseByTaxYearIdCustomerIdAndFilterQuestionId",
    endpoint: "api/FilterQuestionResponse",
    options: {
      headers: {
        "content-type": "application/json"
      }
    }
  },
  {
    name: "UpsertFilterQuestionTileResponse",
    endpoint: "api/FilterQuestionTileResponse",
    options: {
      headers: {
        "content-type": "application/json",
        Host: apiHost
      }
    }
  },
  {
    name: "DeleteFilterQuestionTileResponse",
    endpoint: "api/FilterQuestionTileResponse",
    options: {
      headers: {
        "content-type": "application/json"
      }
    }
  },
  {
    name: "DeleteFilterQuestionResponse",
    endpoint: "api/FilterQuestionResponse",
    options: {
      headers: {
        "content-type": "application/json"
      }
    }
  },
  {
    name: "GetSettings",
    endpoint: "api/Settings",
    options: {
      headers: {
        "content-type": "application/json"
      }
    }
  },
  {
    name: "CreateBlobContainerForCustomer",
    endpoint: "api/BlobContainer",
    options: {
      headers: {
        "content-type": "application/json",
        Host: apiHost
      }
    }
  },
  {
    name: "GetAttachmentURLFromBlobContainer",
    endpoint: "api/Blob",
    options: {
      headers: {
        "content-type": "application/json"
      }
    }
  },
  {
    name: "GetAdditionalDocuments",
    endpoint: "api/Blob",
    options: {
      headers: {
        "content-type": "application/json"
      }
    }
  },
  {
    name: "GetMedicalAdditionalDocuments",
    endpoint: "api/Blob/GetMedicalAttachments",
    options: {
      headers: {
        "content-type": "application/json"
      }
    }
  },
  {
    name: "SendAmendmentsRequestEmail",
    endpoint: "api/AmendmentsRequest",
    options: {
      headers: {
        "content-type": "application/json",
        Host: apiHost
      }
    }
  },
  {
    name: "ContactUs",
    endpoint: "api/ContactUs",
    options: {
      headers: {
        "content-type": "application/json",
        Host: apiHost
      }
    }
  },
  {
    name: "AdditionalInformation",
    endpoint: "api/AdditionalInformation",
    options: {
      headers: {
        "content-type": "application/json",
        Host: apiHost
      }
    }
  },
  {
    name: "AdditionalInformationMedical",
    endpoint: "api/AdditionalInformation/Medical",
    options: {
      headers: {
        "content-type": "application/json",
        Host: apiHost
      }
    }
  },
  {
    name: "SendApprovalEmail",
    endpoint: "api/TaxReturnApproved",
    options: {
      headers: {
        "content-type": "application/json",
        Host: apiHost
      }
    }
  },
  {
    name: "SendCompletionNotification",
    endpoint: "api/CompletionNotification",
    options: {
      headers: {
        "content-type": "application/json",
        Host: apiHost
      }
    }
  },
  {
    name: "SendMedicalCompletionNotification",
    endpoint: "api/MedicalCompletionNotification",
    options: {
      headers: {
        "content-type": "application/json",
        Host: apiHost
      }
    }
  },
  {
    name: "GetTaxYear",
    endpoint: "api/TaxYear",
    options: {
      headers: {
        "content-type": "application/json"
      }
    }
  },
  {
    name: "UploadAttachmentToBlobContainer",
    endpoint: "api/Blob",
    options: {
      headers: {
        "content-type": "application/json",
        Host: apiHost
      }
    }
  },
  {
    name: "UploadMedicalAttachmentToBlobContainer",
    endpoint: "api/Blob/UploadMedicalExpenseDocument",
    options: {
      headers: {
        "content-type": "application/json",
        Host: apiHost
      }
    }
  },
  {
    name: "UploadAdditionalDocuments",
    endpoint: "api/Blob/UploadAdditionalDocuments",
    options: {
      headers: {
        "content-type": "application/json",
        Host: apiHost
      }
    }
  },
  {
    name: "DeleteAttachmentFromBlobContainer",
    endpoint: "api/Blob",
    options: {
      headers: {
        "content-type": "application/json",
        Host: apiHost
      }
    }
  },
  {
    name: "DeleteMedicalAttachmentFromBlobContainer",
    endpoint: "api/Blob/DeleteMedicalAttachment",
    options: {
      headers: {
        "content-type": "application/json",
        Host: apiHost
      }
    }
  },
  {
    name: "GetTaxReturnStatusByCustIdTaxYearAndQuestVersion",
    endpoint: "api/MyAccountStatus",
    options: {
      headers: {
        "content-type": "application/json"
      }
    }
  },
  {
    name: "GetStatusNameByStatusID",
    endpoint: "api/MyAccountStatus",
    options: {
      headers: {
        "content-type": "application/json"
      }
    }
  },
  {
    name: "GetCommunicationsByTaxYear",
    endpoint: "api/Communication",
    options: {
      headers: {
        "content-type": "application/json"
      }
    }
  },
  {
    name: "CreateRegistrations",
    endpoint: "api/Registration",
    options: {
      headers: {
        "content-type": "application/json"
      }
    }
  },
  {
    name: "GetMedicalExpenses",
    endpoint: "api/MedicalExpensesProcess",
    options: {
      headers: {
        "content-type": "application/json"
      }
    }
  },
  {
    name: "UpsertMedicalExpenses",
    endpoint: "api/MedicalExpensesProcess",
    options: {
      headers: {
        "content-type": "application/json",
        Host: apiHost
      }
    }
  }
];

export const httpGET = async (config, parameters, accessToken, hasBody) => {
  let result = null;
  let options = config.options || {};
  options.method = "GET";
  //======debugging purpose only
  // if (config.endpoint === 'GetCustomer') {
  //   
  // }
  //======--
  if (accessToken) {
    options.headers["Authorization"] = `Bearer ${accessToken}`;
  }

  try {
    let response = await fetch(
      baseURL + config.endpoint + "/" + parameters,
      options
    );
    let body = null;
    //console.log(response);
    if (response.status === 200) {
      if (hasBody) {
        try {
          let baseResponse = await response.json();
          if (baseResponse == null) {
            body = null;
          }
          else if (baseResponse.data == null && baseResponse.dataList == null) {
            body = baseResponse.dataList;
          }
          else if (baseResponse.data != null && baseResponse.dataList == null) {
            body = baseResponse.data;
          }

          else if (baseResponse.data == null && baseResponse.dataList != null) {
            body = baseResponse.dataList;
          }
          else {
            body = response;
          }
          // if(body==null){
          //   response.status=baseResponse.statusCode;
          // }
        } catch (e) {
          //  console.log("Invalid JSON: " + e, config);
        } 
      } else {
        try {
          body = await response.text();
        } catch (e) {
          //   console.log("Invalid Response: " + e, config);
        }
      }
    }

    result = {
      status: response.status,
      statusText: response.statusText,
      body: body
    };
  } catch (e) {
    //  console.log(e);
  }
  // console.log("httpGET", result);
  return result;
};


export const httpGETBody = async (config, parameters, accessToken, hasBody) => {
  let result = null;
  let options = config.options || {};
  options.method = "GET";
  //======debugging purpose only
  // if (config.endpoint === 'GetCustomer') {
  //   
  // }
  //======--
  if (accessToken) {
    options.headers["Authorization"] = `Bearer ${accessToken}`;
  }

  try {
    let response = await fetch(
      baseURL + config.endpoint + "/" + parameters,
      options
    );
    let body = null;
    
    //console.log(response);
    if (response.status === 200) {
      if (hasBody) {
        try {
          let baseResponse = await response.json();
          body = baseResponse;

          // if(body==null){
          //   response.status=baseResponse.statusCode;
          // }
        } catch (e) {
          //  console.log("Invalid JSON: " + e, config);
        }
      }
    }

    result = {
      status: response.status,
      statusText: response.statusText,
      body: body
    };
  } catch (e) {
    //  console.log(e);
  }
  // console.log("httpGET", result);
  return result;
};

export const httpGETByArray = async (
  config,
  parameters,
  accessToken,
  hasBody
) => {
  let result = null;
  let options = config.options || {};
  options.method = "GET";

  if (accessToken) {
    options.headers["Authorization"] = `Bearer ${accessToken}`;
  }

  try {
    let response = await fetch(
      baseURL + config.endpoint + "?" + parameters,
      options
    );
    let body = null;
    //console.log(response);

    if (response.status === 200) {
      if (hasBody) {
        try {
          body = await response.json();
        } catch (e) {
          //  console.log("Invalid JSON: " + e, config);
        }
      }
    }

    result = {
      status: response.status,
      statusText: response.statusText,
      body: body
    };
  } catch (e) {
    //console.log(e);
  }
  // console.log("httpGET", result);
  return result;
};

export const httpGETBlob = async (config, accessToken) => {
  let result = null;
  let options = config.options || {};
  options.method = "GET";

  if (accessToken) {
    options.headers["Authorization"] = `Bearer ${accessToken}`;
  }

  try {
    let response = await fetch(config.endpoint, options);
    let body = null;
    // console.log(response);

    if (response.status === 200) {
      try {
        body = await response.blob();
      } catch (e) {
        //   console.log("Invalid JSON: " + e, config);
      }
    }

    result = {
      status: response.status,
      statusText: response.statusText,
      body: body
    };
  } catch (e) {
    // console.log(e);
  }
  // console.log("httpGET", result);
  return result;
};

export const httpPOST = async (config, accessToken, payload, hasBody) => {
  let result = null;
  let options = config.options || {};
  options.method = "POST";

  if (typeof payload !== "undefined") {
    options.body = JSON.stringify(payload);
  }

  options.headers["Authorization"] = `Bearer ${accessToken}`;

  try {

    let response = await fetch(baseURL + config.endpoint, options);
    let body = null;

    if (response.status === 200 && hasBody) {
      try {
        let baseResponse = await response.json();
        if (baseResponse.data == null && baseResponse.dataList == null) {
          body = baseResponse.dataList;
        }
        else if (baseResponse.data != null && baseResponse.dataList == null) {
          body = baseResponse.data;
        }
        else if (baseResponse.data == null && baseResponse.dataList != null) {
          body = baseResponse.dataList;
        }
        else {
          body = null;
        }
      } catch (e) {
        //  console.log("Invalid JSON: " + e, config);
      }
    }
    // if (response.status === 200 && !hasBody) {
    //   body = await response.text();
    // }
    result = {
      status: response.status,
      statusText: response.statusText,
      body: body
    };
  } catch (e) {
    //  console.log(e);
  }
  // console.log("httpPOST:", result);
  return result;
};
export const httpPOSTNotAuthenticated = async (config, payload, hasBody) => {
  let result = null;
  let options = config.options || {};
  options.method = "POST";

  if (typeof payload !== "undefined") {
    options.body = JSON.stringify(payload);
  }

  try {
    let response = await fetch(baseURL + config.endpoint, options);
    let body = null;
    // console.log(response);

    if (response.status === 200 && hasBody) {
      try {
        body = await response.json();
      } catch (e) {
        //  console.log("Invalid JSON: " + e, config);
      }
    }
    if (!hasBody) {
      body = await response.text();
    }
    result = {
      status: response.status,
      statusText: response.statusText,
      body: body
    };
  } catch (e) {
    //  console.log(e);
  }

  return result;
};
export const httpDELETE = async (config, parameters, accessToken, hasBody) => {
  let result = null;
  let options = config.options || {};
  options.method = "DELETE";

  if (accessToken) {
    options.headers["Authorization"] = `Bearer ${accessToken}`;
  }

  try {
    let response = await fetch(
      baseURL + config.endpoint + "/" + parameters,
      options
    );
    let body = null;
    // console.log(response);

    if (response.status === 200) {
      if (hasBody) {
        try {
          body = await response.json();
        } catch (e) {
          //  console.log("Invalid JSON: " + e, config);
        }
      }
    }

    result = {
      status: response.status,
      statusText: response.statusText,
      body: body
    };
  } catch (e) {
    // console.log(e);
  }
  // console.log("httpDELETE", result);
  return result;
};
export const httpPUT = async (config, parameters, accessToken, payload) => {
  let result = null;
  let options = config.options || {};
  options.method = "PUT";

  options.body = JSON.stringify(payload);

  if (accessToken) {
    options.headers["Authorization"] = `Bearer ${accessToken}`;
  }

  try {
    let response = await fetch(
      baseURL + config.endpoint + "/" + parameters,
      options
    );
    let body = null;
    //console.log(response);

    if (response.status === 200) {
      try {
        body = await response.text();
      } catch (e) {
        //  console.log("Invalid Response: " + e, config);
      }
    }

    result = {
      status: response.status,
      statusText: response.statusText,
      body: body
    };
  } catch (e) {
    // console.log(e);
  }
  //console.log("httpPUT", result);
  return result;
};

export const isRunningLocally = () => {
  return (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  );
};
