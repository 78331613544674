import React, { Component, Fragment } from "react";
import Auth from "../../Auth";
import { endpoints, httpPOST, appInsightsCode, acceptedFileTypes } from "../../api";
import SessionStoreManager from "../utilities/SessionStoreManager";
import Constants from "../utilities/Constants";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { CloseButton } from "react-bootstrap";

class ProvideAdditionalFilesMedical extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      subject: "",
      documents: undefined,
      messageSent: "",
      validationText: "",
      documentNamesSend: [],
      filesToUpload:[],
      hasMessage:false,
      hasFiles:false,
      medicalExpense:this.props.medicalExpense
    };

    this.onMessageChange = this.onMessageChange.bind(this);
    this.onSubjectChange = this.onSubjectChange.bind(this);
    this.handleSend = this.handleSend.bind(this);
    this.fileInput = React.createRef();
  }

  componentDidMount() {
    this.setState({ messageSent: false });
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: appInsightsCode
      }
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
  }

  onMessageChange(event) {
    this.setState({ message: event.target.value });
    if(event.target.value){
      this.setState({hasMessage:true,});
    }
    else{
      this.setState({hasMessage:false,});  
    }
  }

  onSubjectChange(event) {
    this.setState({ subject: event.target.value });
  }

  fileTypeValidation(file) {
    var validExts = acceptedFileTypes;
    var extension = file.name.split(".").pop();

    if (validExts.includes(extension)) {
      return true;
    } else {
      return false;
    }
  }

  handleChangeFile = (files) => { 
    if(files.length && files.length>0){
      this.setState({ hasFiles: true,});
    }
    else{
      this.setState({ hasFiles: false,});
    }
    var i;
    var validationString = "";
    var filesUpload = [];
    this.setState({ validationText: "",});
    this.setState({ filesToUpload: [],});
    for (i = 0; i < files.length; i++) {
      if (!this.fileTypeValidation(files[i])) {
        var extension = files[i].name.split(".").pop();
        validationString = validationString + "Files with the extension " + extension + " are not accepted. ";
      }
      if (files[i].size > 20971520) {
        validationString = validationString + "Files larger than 20MB are not accepted. ";
      }
      if (files[i].size  <= 0) {
        validationString = validationString + "Files less than 1 byte are not accepted. ";
      }
      if(files.length>1){
        
        filesUpload.push(files[i].name)
      }
    }
    this.setState({ validationText: validationString })
    this.setState({ filesToUpload: filesUpload })
  }

  async handleSend(event) {
    
    const auth = new Auth();
    this.setState(
      {
        documentNamesSend: []
      }
    )
    //if there are any documents this.fileInput.current.files[0].name
    var currentUser = SessionStoreManager.getValueByKey(
      Constants.currentUserCosmosDBKey
    );
    var validationString = "";
    if (this.state.message !== "") {
      if (this.fileInput.current.files.length > 0) {
        var i;
        
        for (i = 0; i < this.fileInput.current.files.length; i++) {
          //1st check that the files are ok to upload (size and format)
          if (!this.fileTypeValidation(this.fileInput.current.files[i])) {
            var extension = this.fileInput.current.files[i].name.split(".").pop();
            validationString = validationString + "Files with the extension " + extension + " are not accepted. ";
          }
          if (this.fileInput.current.files[i].size > 20971520) {
            validationString = validationString + "Files larger than 20MB are not accepted. ";
          }
          if (this.fileInput.current.files[i].size <= 0) {
            validationString = validationString + "Files less than 1 byte are not accepted. ";
          }
        }
        var filesIncluded = [];
        var filestoPost = [];
        var fileadded = {};

        if (validationString === "") {
          //send the files
          var x;
       
          for (x = 0; x < this.fileInput.current.files.length; x++) {
            await this.loadFile(this.fileInput.current.files[x], auth, this.state.medicalExpense.medicalExpensesProcess.id)
            filesIncluded.push(this.fileInput.current.files[x].name);
            fileadded = {
              "name": this.fileInput.current.files[x].name,
              "extension": this.fileInput.current.files[x].name.split(".").pop(),
              "path": "PostETRQ"
            }
            filestoPost.push(fileadded);

          }
            //Now send the message
            var filesInMessage = "";
            if (filesIncluded.length > 0) {            
              filesInMessage = filesIncluded.join(', ');
            }
            else {
              filesInMessage = "No files included."
            }
          
            var requestToPost = {
              message: this.state.message,
              taxyearId: SessionStoreManager.getValueByKey(
                Constants.taxYearProcessKey
              ).taxYearId,
              customerId: currentUser.id,
              filelist: filesInMessage,
              associatedFiles: filestoPost,
              yearEnd: this.state.medicalExpense.medicalExpensesProcess.accountingYearEndDate
            };

            let response = await httpPOST(
              endpoints.filter(endpoint => endpoint.name === "AdditionalInformationMedical")[0],
              await auth.getToken(),
              requestToPost,
              true
            );

            if (response && response.status === 200) {
              this.setState({ messageSent: true });
            }
        }
      }
      else {
        //Now send the message
        var payload = {
          message: this.state.message,
          customerId: currentUser.id,
          filelist: "No files included",
          taxyearId: SessionStoreManager.getValueByKey(
            Constants.taxYearProcessKey
          ).taxYearId,
          yearEnd: this.state.medicalExpense.medicalExpensesProcess.accountingYearEndDate
        };

        let response = await httpPOST(
          endpoints.filter(endpoint => endpoint.name === "AdditionalInformationMedical")[0],
          await auth.getToken(),
          payload,
          true
        );
        if (response && response.status === 200) {
          this.setState({ messageSent: true });
        }
      }
    }
    else {
      validationString = validationString + " No message included"
    }

    if (validationString !== "") {
      this.setState({ validationText: validationString })
      alert(validationString);
    }
  }
  
  async loadFile(file, auth, medicalId) {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      async function () {
        var blobPayload = {
          // Container name is always customer ID
          containerName: SessionStoreManager.getValueByKey(
            Constants.taxYearProcessKey
          ).customerId,
          blobName: file.name,
          base64FileContent: this.result.split(",")[1],
          taxYearId: SessionStoreManager.getValueByKey(
            Constants.taxYearProcessKey
          ).taxYearId,
          folder:medicalId
        };

        let response = await httpPOST(
          endpoints.filter(
            endpoint =>
              endpoint.name === "UploadAdditionalDocuments"
          )[0],
          await auth.getToken(),
          blobPayload,
          false
        );
        if (response && response.status === 200) {
              
        }
      },
      false
    );
    reader.readAsDataURL(file);
  }

  handleReturn(){
    window.location.reload();
  }

  render() {
    return (
    (this.state.messageSent === false ? <>
      <Fragment>
      <Modal
        size="xl"
        centered
        show={true}
        aria-labelledby="AdditionalFiles"
        className="additionalFiles"
      >
      <Modal.Header>
        <h2>Upload Additional Files</h2>
        <Button className="close" onClick={this.props.hideProvideFurtherInformation}>&#215;</Button>
      </Modal.Header>
      <Modal.Body>
        <section className="additionalFilesBody">
          <h2>Message <b className="additionalFilesMandatory">*</b></h2>
          <textarea rows="10" maxLength="10000" placeholder="" value={this.state.message} onChange={this.onMessageChange.bind(this)}></textarea>
        </section>
        <section className="additionalFilesBody">
          <h2>Attach files <b className="additionalFilesMandatory">*</b></h2>
          <input ref={this.fileInput} onChange={e => this.handleChangeFile(e.target.files)} type="file" multiple accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, image/*, .pdf, application/msword, application/vnd.ms-powerpoint, .txt, .docx, .xlsx, .pptx"></input>

          <label>{this.state.validationText}</label>
          {this.state.filesToUpload.map((item, index) => {
          return (
            <div key={index}>
              <h3>{item}</h3>
            </div>
            );
          })}
        </section>
      </Modal.Body>
      <Modal.Footer>
        <Button type="cancel" className="button cancelUpload" onClick={this.props.hideProvideFurtherInformation}>Cancel</Button>
        <Button type="submit" className="button submit" disabled={(!this.state.hasMessage || !this.state.hasFiles)} onClick={this.handleSend.bind(this)}>Submit</Button>
      </Modal.Footer>
      </Modal>
      </Fragment>
      </>: <>
        <Fragment>
        <Modal
          size="xl"
          centered
          show={true}
          aria-labelledby="AdditionalFiles"
          className="additionalFiles"
        >
          <Modal.Header>
            <h2>Additional Files</h2>
            <Button className="close" onClick={this.props.hideProvideFurtherInformation}>&#215;</Button>
          </Modal.Header>
          <Modal.Body>
            <div className="thankYouMessage">
              <b>Thank you for your message. Your BDO tax team will be in touch shortly.</b>
            </div>
            <button type="submit" onClick={this.props.hideProvideFurtherInformation} className="button closeAdditionalFiles">Close</button>
          </Modal.Body>
          </Modal>
        </Fragment>
      </>) 
    );
  }
}

export default ProvideAdditionalFilesMedical;