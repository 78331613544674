import React, { Component } from "react";
import Auth from "../../Auth";
import { endpoints, httpPOST, appInsightsCode } from "../../api";
import SessionStoreManager from "../utilities/SessionStoreManager";
import Constants from "../utilities/Constants";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      subject: "",
      messageSent: ""
    };

    this.onMessageChange = this.onMessageChange.bind(this);
    this.onSubjectChange = this.onSubjectChange.bind(this);
    this.handleSend = this.handleSend.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
  }
  componentDidMount() {
    this.setState({ messageSent: false });
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: appInsightsCode
      }
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
  }
  onMessageChange(event) {
    this.setState({ message: event.target.value });
  }

  onSubjectChange(event) {
    this.setState({ subject: event.target.value });
  }

  onMessageSubmit() {
    
    if(this.state.subject == "" || this.state.message == "")
    {
      var btn = document.getElementById("BtnSubmit").disabled;
      alert("Please complete entire Form");
    } else {
      var btn = document.getElementById("BtnSubmit").enabled;
      this.handleSend();
    }
  }

  async handleSend(event) {
    const auth = new Auth();

    var currentUser = SessionStoreManager.getValueByKey(
      Constants.currentUserCosmosDBKey
    );
    var requestToPost = {
      subject: this.state.subject,
      message: this.state.message,
      customerId: currentUser.id,
      taxYearId: SessionStoreManager.getValueByKey(
        Constants.taxYearProcessKey
      ).taxYearId,
    };
    let response = await httpPOST(
      endpoints.filter(endpoint => endpoint.name === "ContactUs")[0],
      await auth.getToken(),
      requestToPost,
      true
    );
    if (response && response.status === 200) {
      this.setState({ messageSent: true });
    }
  }

  render() {
    var formBody = "";
    if (this.state.messageSent === false)
    {
      formBody =  (
        <div>
        <form className="pageContent">
          <label>Subject</label>
          <input type="text" value={this.state.subject} onChange={this.onSubjectChange.bind(this)} ></input>
          <section>
            <label>Message</label>
            <textarea maxLength="10000" placeholder="Please give a brief description of your query. Your BDO tax team will be in touch shortly." value={this.state.message} onChange={this.onMessageChange.bind(this)}></textarea>
          </section>
          </form>
          <div className="buttonDeck"><button id="BtnSubmit" type="submit" onClick= {this.onMessageSubmit.bind(this)} className="button">Send message</button></div>
        </div>
        );

    }
    else
    {
      formBody =  (
        <div className="pageContent">
         <h2 className="goToMessages"><p>Thank you for your message.</p><div>Your BDO tax team will be in touch shortly.</div></h2>
         
        </div>
      );
     
    }
    return (
      <section className="sectionContent">
      <div className="page contactUs">
        <header>
          <h2>Contact Us</h2>
        </header>
        <div className="pageContent">
        {formBody}
        </div>
      </div>
      </section>
    );
  }
}

export default ContactUs;

