import React from 'react';

import Accordion from "react-bootstrap/Accordion";
import Card from 'react-bootstrap/Card';

import Button from "react-bootstrap/Button";

export default function FAQS() {
   return (
    <div className="faqs">
     <Accordion>
    <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="0">
      If I&rsquo;ve not yet registered, how can I do this?
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="0">
      <Card.Body>
      <ul>
                <li>If you&rsquo;ve been invited to use the online personal tax platform, we will have sent you an e-mail from bdoprivate@ecomms.bdo.co.uk. If you can&rsquo;t find your invite email, we suggest checking your junk mailbox initially or alternatively contacting your usual BDO tax team to send you a copy.</li>
                <li>If you&rsquo;ve not been invited to use the online personal tax platform and are an existing customer then please contact your usual BDO tax team who can arrange for you to receive an e-mail invitation which will allow you to register.</li>
                <li>If you are not an existing customer then you can make an enquiry at bdoprivate.bdo.co.uk.</li>
            </ul>
      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
  <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="1">
      What file types can I upload?
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="1">
      <Card.Body><ul>
                <li>Files with the following extensions can be uploaded: .csv, .pdf, .txt, .docx, .xlsx, .pptx, .doc, .xls, .ppt, .png, .jpeg, .bmp, .tiff, .pdf, .pages, .jpg, .gif, .tiff, .eml, .msg, .vsd, .vsdx, .html, .xsl, .xml, .preview, .xslt, .xslm, .htm, .docm, .xps, .log, .xlm, .pptm</li>
            </ul></Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
  <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="2">
      What web browsers are compatible with the online personal tax platform?  
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="2">
      <Card.Body><ul>
                <li>Google Chrome is our preferred browser, and other major browsers such as Microsoft Edge and Apple Safari are also compatible. Internet Explorer and Mozilla Firefox are not supported. </li>
            </ul></Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
  <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="3">
      What if I want to change my income types from what I initially selected?
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="3">
      <Card.Body><ul>
                <li>An option to change this can be found at the bottom of the left hand navigation pane within your questionnaire as &rsquo;Review or add income sources&rsquo;.</li>
                <li>We&rsquo;ll then ask you to tell us what types of income that you had for the tax year in question. </li>
                <li>Each option on screen corresponds to a more detailed section within the questionnaire.</li>
            </ul></Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
  <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="4">
      Can I return to complete the questionnaire later?
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="4">
      <Card.Body><ul>
                <li>Yes you can logout and come back to the questionnaire at any time, the platform saves your information regularly on each page as you progress.</li>
            </ul></Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
  <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="5">
      I don&rsquo;t think your e-mails have reached me. How can I check?
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="5">
      <Card.Body> <ul>
                <li>We&rsquo;d suggest initially checking your Junk mailbox and making sure that the bdo.co.uk domain is whitelisted.</li>
            </ul></Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
  <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="6">
      I&rsquo;ve already submitted my tax return information but have remembered some additional things that I need to tell you about. Can I still send them via this platform?
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="6">
      <Card.Body>  <ul>
               <li>Please log back into your account at bdoprivate.bdo.co.uk, and under the &rsquo;Your tax returns&rsquo; section you&rsquo;ll find a link to share additional files just below &rsquo;Your questionnaire&rsquo;.</li>
            </ul></Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
  <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="7">
      What happens after I&rsquo;ve provided you with my tax return information?
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="7">
      <Card.Body> <ul>
                <li>Your BDO Tax team prepare your tax return and upload it for your review. You&rsquo;ll receive a notification email when this is ready.</li>
                <li>We&rsquo;ll ask you to review your tax return and to either approve it or send a query/provide more information using this same platform.</li>
                <li>We&rsquo;ll let you know once your tax return has been successfully submitted to HMRC.</li>
                <li>Remember, you can check progress under the &rsquo;Your tax returns&rsquo; section at any time.</li>
            </ul></Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
  <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="8">
      How do I look at last year&rsquo;s tax return and associated files? 
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="8">
      <Card.Body> <ul>
                <li>After logging in at bdoprivate.bdo.co.uk, go to &rsquo;Your Tax Returns&rsquo; and you will see all previous year files at the bottom of the page including any documents that you provided to us.</li>
            </ul></Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
  <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="9">
      What if I want to change the email address that I use to login? 
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="9">
      <Card.Body> <ul>
                <li>Please contact your BDO tax team if you'd like to change the email address you login with. They will be able to update your account details on your behalf.</li>
            </ul></Card.Body>
    </Accordion.Collapse>
  </Card>  
</Accordion>               
    </div>
  );
}
