import React, { Component, Fragment } from "react";
import MedicalExpensesForm from "./MedicalExpensesForm";
import Auth from "../../Auth";
import { endpoints, httpGET, appInsightsCode } from "../../api";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import Constants from "../utilities/Constants";
import SessionStoreManager from "../utilities/SessionStoreManager";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import ProvideAdditionalFilesMedical from "../misc/ProvideAdditionalFilesMedical";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

class MedicalExpenses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allMedicalExpenses: {},
      selectedMedicalExpesesProcess: {},
      allMedicalExpensesYears:[],
      showFurtherInformation: false,
      currentItem:{},
      showAdditionalDocumentsForm:false,
      documentsRetrieved:false,
      additionalDocumentsList:[]
    };
    this.appInsightsCode = appInsightsCode;
    this.hideProvideFurtherInformation = this.hideProvideFurtherInformation.bind(this);
  }

  componentDidMount() {
    const auth = new Auth();
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: appInsightsCode,
      },
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
    this.getAllMedicalExpensesProcesses(auth);
  }

  handleMoreInfo(item) {
    this.setState({
      currentItem:item
    });
    this.setState({
      showFurtherInformation:true,
      showAdditionalDocumentsForm:false
    });
  }

  hideProvideFurtherInformation(){
    this.setState({
      showFurtherInformation:false
    });
    window.location.reload();
  }

  handleViewAdditionalFiles(item) {
    this.setState({ currentItem: item});
    this.setState({
      documentsRetrieved: false,
    });
    this.setState({
      showAdditionalDocumentsForm: true,
    });
    this.getExistingBlobs(item.medicalExpensesProcess.id, item.medicalExpensesProcess.accountingYearEndDate);
  }

  handleCloseAdditionalFiles() {
    this.setState({
      showAdditionalDocumentsForm: false,
      documentsRetrieved:false,
      currentItem:{}
    });
  }

  async getExistingBlobs(id, accountingYearEndDate) {
		var currentUser = SessionStoreManager.getValueByKey(
			Constants.currentUserCosmosDBKey
		);
    const auth = new Auth();
		var parameters =
			currentUser.id +
			"/" +
			id +
			"/" +
			accountingYearEndDate;

		let response = await httpGET(
			endpoints.filter(
				(endpoint) => endpoint.name === "GetMedicalAdditionalDocuments"
			)[0],
			parameters,
			await auth.getToken(),
			true
		);

		if (response && response.status === 200) {
      this.setState({additionalDocumentsList: response.body})
      this.setState({
        documentsRetrieved: true,
      });
			return response.body;
		} else {
			return null;
		}
	}
  async getAllMedicalExpensesYears(allMedicalExpenses){
    const availableYears = [];
    allMedicalExpenses.forEach(element => {
      var year = element.medicalExpensesProcess.accountingYearEndDate.split('-')[0]
      if(availableYears.indexOf(year)===-1){
        availableYears.push(year);
      }
    });
  this.setState({allMedicalExpensesYears:availableYears})
  }

  async getAllMedicalExpensesProcesses() { 
    const auth = new Auth();
    var currentUser = SessionStoreManager.getValueByKey(
      Constants.currentUserCosmosDBKey
    );
    var parameters =
      currentUser.id;

    let response = await httpGET(
      endpoints.filter((endpoint) => endpoint.name === "GetMedicalExpenses")[0],
      parameters,
      await auth.getToken(),
      true
    );
    var medicalExpenses;
    if (response && response.status === 200) {
      medicalExpenses = response.body;
      this.setState({
        allMedicalExpenses: medicalExpenses,
      });     
      await this.getAllMedicalExpensesYears(response.body);
    }
  }
  
  render() {
    let medicalForm, medicalHead = "", medicalProcesses = "";
    let allMedical = this.state.allMedicalExpenses;
    var provideFurtherInformation = "";
    if(this.state.showFurtherInformation){
      provideFurtherInformation = (
        <>
          <ProvideAdditionalFilesMedical
            hideProvideFurtherInformation = {this.hideProvideFurtherInformation} 
            medicalExpense={this.state.currentItem}         
          />
        </>

      )
    }

    var additionalFilesModal = "";
    if (this.state.showAdditionalDocumentsForm) {
      if(this.state.documentsRetrieved){
        var filestoshow = "";
        filestoshow = this.state.additionalDocumentsList.map((value, index) => {
          return (
            <div className="description">
              <a
                key={value.FileName}
                title={value.FileName}
                target="_blank"
                rel="noopener noreferrer"
                className="fileDownload"
                href={value.FileLink}
              >
                {value.FileName.split('/')[1]}
              </a>
              <div className="date">
                <span>Last modified</span>
                {value.LastModified}
              </div>
            </div>
          );
        });
        additionalFilesModal = (
          <Fragment>
            <Modal
              size="md"
              centered
              show={true}
              onHide={this.handleCloseAdditionalFiles.bind(this)}
              aria-labelledby="AdditionalFiles"
              className="additionalFiles"
            >
              <Modal.Header closeButton>Additional Files</Modal.Header>
              <Modal.Body>{filestoshow}</Modal.Body>
              <Modal.Footer>
                <Button
                  className="button"
                  onClick={() => this.handleMoreInfo(this.state.currentItem)}
                >
                  Add New
                </Button>
              </Modal.Footer>
            </Modal>
          </Fragment>
        );
      }
      else{
        additionalFilesModal = (
          <Fragment>
            <Modal
              size="md"
              centered
              show={true}
              onHide={this.handleCloseAdditionalFiles.bind(this)}
              aria-labelledby="AdditionalFiles"
              className="additionalFiles"
            >
              <Modal.Header closeButton>Additional Files</Modal.Header>
              <Modal.Body>
                <Fragment>
                <div className="spinnerModal medicalSpinner">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
                </Fragment>
              </Modal.Body>
            </Modal>
          </Fragment>
        );
      }
      
    }

    if(allMedical.length > 0){
      medicalProcesses = this.state.allMedicalExpensesYears.map((item,keyYear)=>(
        <Fragment key={keyYear}><span className="medExpenseYearHeader"><h2>{item}</h2></span>
        {this.state.allMedicalExpenses.filter(i=>i.medicalExpensesProcess.accountingYearEndDate.split('-')[0]==item).map((item,key)=>(
          <div key={key} className="medContainer_annual">
            <header className={(this.state.allMedicalExpenses.filter(i=>i.medicalExpensesProcess.accountingYearEndDate.split('-')[0]==item.medicalExpensesProcess.accountingYearEndDate.split('-')[0]).length -1 == key ? 'medExpenseYearHeader' : '')}>
            <div className={(item.medicalExpensesProcess.status == "Complete" ? 'medExpenseComplete' : (item.medicalExpensesProcess.status=="Open"? 'medExpenseOpen' : 'medExpenseStarted'))}></div>
            </header>
          <div className={"medCard " + (item.medicalExpensesProcess.status == "Complete" ? 'medCardComplete' : '')}>
            <section className={"medCardPrimary " + (item.medicalExpensesProcess.status == "Complete" ? 'medCardPrimaryComplete' : '')}>
            <Link className="medQuestionnaireLink" to={`/medicalquestionnaire/${item.medicalExpensesProcess.id}`}><div className="taxFileType file medFileIcon"></div></Link>
            <div className="taxFileDetail primarySection">
            <div className="medDetails">
              <Link className="medQuestionnaireLink" to={`/medicalquestionnaire/${item.medicalExpensesProcess.id}`}><h3 className="taxFileYourName">{"Medical Expenses"}</h3></Link>

              <h2 className="medDate">
                <span>
                  {(new Date(item.medicalExpensesProcess.accountingYearEndDate).toLocaleDateString("en-GB"))}
                </span>
            </h2>                 
            </div>
            <h2 className="medPracticeName">{item.medicalExpensesProcess.generalPracticeName}</h2>
            <div className="taxFileYourName">Status</div>
            <div className="medicalExpenseStatus"><span>{item.medicalExpensesProcess.status}</span></div>
            </div>
            </section>
            
            {(item.medicalExpensesProcess.status == "Complete") &&             <p className="whatsHappening">
              If you need to share additional files, please upload the files and
              supply explanatory information{" "}
              <Link  onClick={() => this.handleMoreInfo(item)}>
                here
              </Link>
              .
            </p>}
            {(item.HasAdditionalDocuments)&&            <section className="fileRepository">
              <Link
                onClick={() => this.handleViewAdditionalFiles(item)}
                className="button"
              >
                View additional files
              </Link>
            </section>}
            </div>
          </div>
        ))}
        </Fragment>
      ));
      medicalHead = (<header className="medicalHead" hidden><h2>Your medical expenses</h2></header>  );   
    }
    else
    {
      medicalProcesses =  <Fragment>
      <div className="spinnerModal">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    </Fragment>
    }

    
    return (
      <Fragment>    
        {additionalFilesModal}
        {provideFurtherInformation}
        {medicalForm}
        {medicalHead}<div className="medicalArchive">{medicalProcesses}</div> 
      </Fragment>
    );
  }
}

export default MedicalExpenses;