import React, { Component } from "react";
import NavControlsGenerator from "../utilities/NavControlsGenerator";

class TermsOfService extends Component {
  componentDidMount() {
  }
  render() {
    return (
      <section className="sectionContent"><div className="page">
        <header>
          <h2>Terms & Conditions</h2>
        </header>
        <div className="pageContent"></div>
        <NavControlsGenerator currentPageNavCode={92}></NavControlsGenerator>
      </div></section>
    );
  }
}

export default TermsOfService;
