import React, { Component } from "react";
import NavControlsGenerator from "../utilities/NavControlsGenerator";
import { InputGroupCheckbox } from "react-bootstrap/InputGroup";

class Quote extends Component {
  componentDidMount() {
  }
  handleNextNavigation = c => {
    this.props.history.push("/identityverificationquestions");
  };
  render() {
    return (
      <section className="sectionContent">
        <div className="page exitPage quote">
          <header>
            <h2>Quote</h2>
          </header>
          <div className="pageContent">
            <section>
              <h3>Your tailored personal Tax Return Quote is</h3>
              <h2>
                £--- <span>+VAT</span>
              </h2>
              <h4>Our service includes:</h4>
              <ul>
                <li>Proof point 1</li>
                <li>Proof point 2</li>
                <li>Proof point 3</li>
              </ul>
            </section>

            <section>
              <p>
                Would you like to book a one-to-one meeting with a BDO personal
                tax advisor to discuss your personal tax return once completed
                and any planning opportunities identified?
              </p>
              <form className="checkbox">
                <input type="checkbox" name="meetingConfirmation" />
                <label>1hr £150</label>
              </form>

              <cite>*Please note: no follow-up notes are included</cite>
            </section>

            <section>
              <p>
                The engagement won’t be confirmed until you agree with the quote
                and the Scope of Business. We will contact you following
                successful completion of our client acceptance procedure to
                confirm..
              </p>
              <p>Please read the Scope of Business</p>
              <form className="checkbox">
                <input type="checkbox" name="businessScope" />
                <label>I accept the Scope of Business</label>
              </form>
              <form className="checkbox">
                <input type="checkbox" name="termsConditions" />
                <label>I accept the General Terms and Conditions</label>
              </form>
            </section>
          </div>

          <NavControlsGenerator currentPageNavCode={90}></NavControlsGenerator>
        </div>
      </section>
    );
  }
}

export default Quote;
