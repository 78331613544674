import React, { Component } from "react";

class EmailVerificationExplanation extends Component {
  render() {
    return <section className="sectionContent"><div className="page">
    <header><h2>Email Verification Explanation</h2></header>  
<div className="pageContent"></div>
</div></section>;
  }
}

export default EmailVerificationExplanation;
