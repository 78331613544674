import React, { Component } from "react";
import { ReactComponent as Logo } from "../icons/bdoLogo.svg";

import Constants from "./utilities/Constants";

import { clientIdB2C, endpoints, httpPOST, httpGET, pwdAuthorityB2C, resetPasswordPolicyB2C, webURL } from "../api";
import SessionStoreManager from "./utilities/SessionStoreManager";
import Auth from "../Auth";


class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      currentUserEmail: this.getUserEmail()
    };
  }

  toggleMenu = () => {
    this.setState({
      active: !this.state.active
    });
  };

  getUserEmail() {
    var auth = new Auth();
    return auth.getCurrentAccount().idTokenClaims.signInName;
  }

  async updateNavNode(NavNode) {
    const auth = new Auth();

    let taxYearId = SessionStoreManager.getValueByKey(
      Constants.taxYearProcessKey
    ).taxYearId;
    await this.getTaxYearProcess(auth, taxYearId);
    let taxYearProcess = SessionStoreManager.getValueByKey(
      Constants.taxYearProcessKey
    );
   
    taxYearProcess.currentNavNodeCode = NavNode;
    await this.updateDB(auth, taxYearProcess);

    window.location.reload();
  }

  async getTaxYearProcess(auth, taxYearId) {
    var currentUser = SessionStoreManager.getValueByKey(
      Constants.currentUserCosmosDBKey
    );
    var parameters = currentUser.id + "/" + taxYearId;

    let response = await httpGET(
      endpoints.filter((endpoint) => endpoint.name === "GetTaxYearProcess")[0],
      parameters,
      await auth.getToken(),
      true
    );

    if (response && response.status === 200) {
      SessionStoreManager.setValueForKey(
        Constants.taxYearProcessKey,
        response.body
      );
    }
  }

  async updateDB(auth, taxYearObj) { 
    let response = await httpPOST(
      endpoints.filter(
        endpoint => endpoint.name === "GetOrCreateTaxYearProcess"
      )[0],
      await auth.getToken(),
      taxYearObj,
      true
    );
    if (response && response.status === 200) {
      // Update cache once DB was successfully updated
      SessionStoreManager.setValueForKey(
        Constants.taxYearProcessKey,
        taxYearObj
      );
    }
  }

  handlePasswordReset = c => {
    //console.log("Password reset")
    window.location.href =
      // "https://bdoukb2cdevief.b2clogin.com/bdoukb2cdevief.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_BDO_PasswordReset&client_id=055d82be-6483-48bf-a2f6-616852c94394&nonce=defaultNonce&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fsignupredirect&scope=openid&response_type=id_token&prompt=login&state=215e2300-38b0-4921-bf7f-2212d4a8ad66";
      //"https://bdoukb2cdevief.b2clogin.com/bdoukb2cdevief.onmicrosoft.com/b2c_1a_bdo_passwordreset/oauth2/v2.0/authorize?client_id=055d82be-6483-48bf-a2f6-616852c94394&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fsignupredirect&response_type=id_token&scope=openid%20profile&state=215e2300-38b0-4921-bf7f-2212d4a8ad66";

      pwdAuthorityB2C +
      "/" +
      resetPasswordPolicyB2C +
      "/oauth2/v2.0/authorize?client_id=" +
      clientIdB2C +
      "&redirect_uri=" +
      webURL +
      "signupredirect" +
      "&response_type=id_token&scope=openid%20profile&state=215e2300-38b0-4921-bf7f-2212d4a8ad66";
  };

  handleSignout = c => {
    // console.log("Sign out")
    const auth = new Auth();
    var currentUser = auth.getCurrentAccount();
    auth.logout({
      account: currentUser
    })
    SessionStoreManager.clearAllKeys();
  };

  render() {
    const menuVis = this.state.active ? "is-active" : "";
  
    let isMenuVisible = "";
    let isMedical = SessionStoreManager.getValueByKey(Constants.isMedical); 
    let isMedicalCallToAction = SessionStoreManager.getValueByKey(Constants.medicalCallToAction);

    if (window.location.pathname === "/") {
      isMenuVisible = "hidden";
    } else {
      isMenuVisible = "";
    }
   
    let yourTaxReturnsOption, medicalOption, messagesOption = "";
    if (window.location.pathname === "/messages")
    {
      messagesOption = (
        <section className="messagesPage active">
              <a
                  className="contactformLink"
                  onClick={() => this.updateNavNode(123)}
                >
                  Your messages
                </a>
              </section>
      );
    }
    else
    {
      messagesOption = (
        <section className="messagesPage">
              <a
                  className="contactformLink"
                  onClick={() => this.updateNavNode(123)}
                >
                  Your messages
                </a>
              </section>
      );
    }
    if (window.location.pathname === "/mytaxreturns")
    {
      yourTaxReturnsOption = (
        <section className="taxReturnPage active">
                <a onClick={() => this.updateNavNode(140)}>Your tax returns</a>
              </section>
      );
    }
    else
    {
      yourTaxReturnsOption = (
        <section className="taxReturnPage">
        <a onClick={() => this.updateNavNode(140)}>Your tax returns</a>
      </section>
      );
    }
    if (isMedical)
    {
      if (isMedicalCallToAction)
      {
        if (window.location.pathname === "/medical")
        {
            medicalOption = (
                      <section className="messagesPage active">
                        <a
                            className="contactformLink"
                            onClick={() => this.updateNavNode(250)}
                          >
                            <span className="notificationIcon">!</span> Your medical expenses
                          </a>
                        </section>
                );
        }
        else
        {
          medicalOption = (
            <section className="messagesPage">
              <a
                  className="contactformLink"
                  onClick={() => this.updateNavNode(250)}
                >
                  <span className="notificationIcon">!</span> Your medical expenses
                </a>
              </section>
          );
        }
        
      }
      else
      {
        if (window.location.pathname === "/medical")
        {

          medicalOption = (
            <section className="messagesPage active">
              <a
                  className="contactformLink"
                  onClick={() => this.updateNavNode(250)}
                >
                  Your medical expenses
                </a>
              </section>
      );
        }
        else
        {
          medicalOption = (
            <section className="messagesPage">
              <a
                  className="contactformLink"
                  onClick={() => this.updateNavNode(250)}
                >
                  Your medical expenses
                </a>
              </section>
      );
        }
        
      }
      
    }
   
    return (
      <header className="productTaskbar">
        <div className="desktopWrapper">
          <section className="branding">
            <a
              className="homepageLink"
              href="https://www.bdo.co.uk/en-gb/services/tax/private-client-services"
              title="bdoPrivate"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Logo />
              <h2>United Kingdom</h2>
            </a>
          </section>

          <section style={{ visibility: isMenuVisible }} className="globalNav">
            <button
              className={`hamburger hamburger--collapse  ${menuVis}`}
              onClick={this.toggleMenu}
              type="button"
            >
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
            <nav className="globalNavigation">             
              <section className="userMobileView">
                <span className="userEmail">{this.state.currentUserEmail}</span>
              </section>
              {yourTaxReturnsOption}
              {medicalOption}
              {messagesOption}
              <section className="utilities">
                <div className="userSection">
                  <span className="userEmail">{this.state.currentUserEmail}</span>
                </div>
                <div className="menuAvatar"></div>
                <nav className="subMenu">{/* <a
                  className="contactformLink"
                  onClick={() => this.updateNavNode(120)}
                >
                  Contact us
                </a> */}
                <a onClick={() => this.handlePasswordReset()}>Reset password</a>
                <a onClick={() => this.handleSignout()} className="logout">
                  Sign out
                </a>
              </nav>
                </section>
            </nav>
            <div className="menuOverlay"></div>
          </section>
        </div>
      </header>
    );
  }
}

export default Header;