import { EventType } from "@azure/msal-browser";
import { authProvider, authenticationParameters } from "./components/utilities/authProvider";

class Auth {
  isLoggedIn() {
    return authProvider.getAllAccounts().length > 0;
  }

  getCurrentAccount() {
    return authProvider.getActiveAccount();
  }

  logout() {
    authProvider.logout();
  }

  async getFullToken() {
    var activeAccount;
    const accounts = authProvider.getAllAccounts();

      if (accounts.length == 1) {
        activeAccount = accounts[0];
      }
      if(accounts.length > 1){
        // If we try to get the token silently with B2C_1A_BDO_ForgotPassword policy it will fail
        activeAccount = accounts.find(element => element.idTokenClaims.acr == 'b2c_1a_bdo_signin');
        if(activeAccount == null){
          activeAccount = accounts[0];
        }
      }
    
    if (authProvider.getActiveAccount()) {
      var request = { ...authenticationParameters, account: activeAccount };
      var token = await authProvider.acquireTokenSilent(request).catch((err) => {
        console.error(err);
        authProvider.acquireTokenRedirect(request);
      });
      return token;
    }
    return null;
  }

  async getToken() {
    var fullToken = await this.getFullToken();
    return fullToken ? fullToken.accessToken : null;
  }

  async currentUser() {
    var token = await this.getFullToken();
    if (token) {
      return {
        name: token.idTokenClaims.name,
        firstName: token.idTokenClaims.given_name,
        lastName: token.idTokenClaims.family_name,
        email: token.idTokenClaims.email ? token.idTokenClaims.email : token.idTokenClaims.signInName,
        exp: token.expiresOn
      };
    } else {
      return null;
    }
  }

  isTokenValid() {
    var token = this.getToken();
    if (token) {

      if (token.expiresOn < new Date()) {
        //  console.log("TOKEN EXPIRED - " + decoded.exp);
        return false;
      } else {
        //  console.log("TOKEN VALID - " + decoded.exp);
        return true;
      }
    }

    return false;
  }

  onLogin(callback, thisRef) {
    var callbackWithThis = callback.bind(thisRef);
    authProvider.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        callbackWithThis();
      }
    });
  }

  loginRedirect() {
    authProvider.loginRedirect();
  }
}

export default Auth;
