import React, { Component, Fragment } from "react";

import { withRouter } from "react-router-dom";
import { webURL, appInsightsCode, unsupportedBrowsers } from "../../api";
import Header from "../header";
import Footer from "../footer";
import FAQs from "./FAQs"
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import CookieBanner from "react-cookie-banner";
import NewCustomerRegistration from "../surveyjs/NewCustomerRegistration";
import { ReactComponent as Logo } from "../../icons/bdoLogo.svg";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

class MarketingHomepage extends Component {
  constructor() {
    super();
    this.userAgent = window.navigator.userAgent;

    this.state = {
      isUnsupportedBrowser: false,
      showRegistration: false,
      showFAQs: false
    };
  }

  componentDidMount() {
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: appInsightsCode
      },
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();

    //console.log(unsupportedBrowsers);
    var unsupportedBrowsersArray = unsupportedBrowsers.split(",").map(String);

    for (var i = 0; i < unsupportedBrowsersArray.length; i++) {
      // console.log(unsupportedBrowsersArray[i]);
      if (
        this.userAgent
          .toLowerCase()
          .indexOf(unsupportedBrowsersArray[i].toLowerCase()) > -1
      ) {
        this.setState({ isUnsupportedBrowser: true });
        // console.log("Unsupported browser");
        break;
      }
    }
  }
  handleNextNavigation = (c) => {
    window.location.href = webURL + "mytaxreturns";
  };
  handleNextNavigationRegister = (c) => {
    this.setState({
      showRegistration: true
    });
  };
  handleNextNavigationRegisterCancel = (c) => {
    this.setState({
      showRegistration: false
    });
  };
  handleNextNavigationFAQ = (c) => {
    this.setState({
      showFAQs: true
    });
  };
  handleNextNavigationFAQCancel = (c) => {
    this.setState({
      showFAQs: false
    });
  };

  render() {
    var fAQsModal = ""
    const message =
      "This site uses cookies to provide you with a more responsive and personalised service. By using this site you agree to our use of cookies. Please read our privacy statement for more information on the cookies we use and how to delete or block them.";
    const styles = {
      banner: {
        position: "fixed",
        textAlign: "center",
        background: "rgba(101, 124, 145, 0.8)",
        width: "100%",
        height: "auto",
        zIndex: 99999,
        bottom: 0,
        color: "rgb(255, 255, 255)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "1.5rem",
      },
      button: {
        padding: "0 1.5rem",
        border: "none",
        borderRadius: "5px",
        boxShadow: "none",
        fontSize: "1rem",
        fontWeight: 700,
        color: "rgb(255, 255, 255)",
        cursor: "pointer",
        display: "flex",
        height: "2.5rem",
        textTransform: "uppercase",
        verticalAlign: "middle",
        minWidth: "10rem",
        position: "static",
        margin: "0",
      },
      message: {
        lineHeight: "1.4em",
        fontWeight: "500",
        color: "var(--paleCharcoal_30)",
        width: "100%",
        display: "block",
        textAlign: "center",
        margin: "0 auto",
        fontSize: "14px",
        maxWidth: "calc(960px - 3rem)",
        padding: "1rem 1.5rem",
      },
      link: {
        textDecoration: "none",
        fontWeight: "bold",
      },
    };
    if (this.state.showFAQs) {
      fAQsModal =  (
        <Modal
        className="faqsModal"
          size="lg"
          centered
          show={true}
          onHide={this.handleNextNavigationFAQCancel.bind(this)}
          aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
    <Modal.Title>Frequently asked questions</Modal.Title>
  </Modal.Header>
          <Modal.Body>
          <FAQs />
          </Modal.Body>
          <Modal.Footer>            
            {/* <Button
              className="button"
              onClick={this.handleNextNavigationFAQCancel.bind(this)}
            >
              Close
            </Button> */}
          </Modal.Footer>
        </Modal>
      );
    }
    
    var contentToRender = "";

    if (!this.state.isUnsupportedBrowser) {
      if (!this.state.showRegistration) {
        
          contentToRender = (
            <Fragment>
              <div className="App marketingPage altDesign">
                <header className="productTaskbar MSC">
                  <div className="fixedWidthWrapper">
                    <section className="branding">
                      <a
                        className="homepageLink"
                        href="https://www.bdo.co.uk/en-gb/services/tax/private-client-services"
                        title="BDO private client services"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Logo />
                        <h2>United Kingdom</h2>
                      </a>
                    </section>
  
                    <section className="globalNav">
                          <a className="mastheadLink"
                              href="https://www.bdo.co.uk/en-gb/services/tax/private-client-services" title="Our services"
                              target="_blank" rel="noopener noreferrer">Our services</a>
                          <a className="mastheadLink"
                              href="https://www.bdo.co.uk/en-gb/contact"
                              title="Contact us" target="_blank" rel="noopener noreferrer">Contact us</a>
                                <button title="Frequently asked questions" className="mastheadLink emphasise FAQ" onClick={this.handleNextNavigationFAQ.bind(this)}>FAQs</button>
                          <button className="mastheadLink emphasise enquiry" title="Make and enquiry" onClick={this.handleNextNavigationRegister.bind(this)}>Make an enquiry</button>
                          <button className="mastheadLink emphasise signIn" title="Sign in" onClick={this.handleNextNavigation.bind(this)}>Sign in</button>
                      </section> </div>
                </header>
  
              <article>
                  {/* <img src={bgImage} alt="BDO online personal tax" /> */}
                  <div className="fixedWidthWrapper">
                      <section className="marketing">
                          <div className="CTA_strap">
                              <span className=""></span>
                              <h1>BDO Online <span>Tax returns</span></h1>
                              <h3 className="CTA_subStrap">
                                  <span>Award-winning advice</span>
                                  <span>Secure personal portal</span>
                                  <span>Fair and upfront pricing</span></h3>
                              <span className=""></span>
                          </div>
                          <footer className="globalFooter">
                              <div className="desktopWrapper">
                                  <section className="bdoInformation">
                                      <div className="legalPrivacy"><span className="copyright">Copyright © <span
                                                  className="dynamicYear">{(new Date().getFullYear())}</span> BDO LLP</span>
                                                    <a href="#" onClick={this.handleNextNavigationFAQ.bind(this)}>FAQs</a>
                                                  <a
                                              href="https://www.bdo.co.uk/en-gb/privacy-statement" target="_blank"
                                              rel="noopener noreferrer">Legal &amp; Privacy</a><a
                                              href="https://www.bdo.co.uk/en-gb/contact"
                                              title="Contact us" target="_blank" rel="noopener noreferrer">Contact us</a>
                                      </div>
                                  </section>
                                  <section className="socialMedia">
                                      <div className="socialItem"><a href="https://www.facebook.com/BDOLLP/" title="Facebook"
                                              target="_blank" rel="noopener noreferrer"><span
                                                  className="socialIcon facebook"></span></a></div>
                                      <div className="socialItem"><a href="https://twitter.com/bdoaccountant" title="X"
                                              target="_blank" rel="noopener noreferrer"><span
                                                  className="socialIcon twitter"></span></a></div>
                                      <div className="socialItem"><a href="https://www.linkedin.com/company/bdo-llp"
                                              title="Linkedin" target="_blank" rel="noopener noreferrer"><span
                                                  className="socialIcon linkedin"></span></a></div>
                                      <div className="socialItem"><a href="https://www.youtube.com/user/bdollp"
                                              title="Youtube" target="_blank" rel="noopener noreferrer"><span
                                                  className="socialIcon youtube"></span></a></div>
                                      <div className="socialItem"><a href="https://www.instagram.com/bdo_uk" title="Instagram"
                                              target="_blank" rel="noopener noreferrer"><span
                                                  className="socialIcon instagram"></span></a></div>
                                  </section>
                              </div>
                          </footer>
                      </section>
                      <section className="responsivePanel">
                          <div className="CTA_strap">
                              <h2>How to get online with BDO</h2>
                              <div className="flexbox">
                              <aside className="newClient">
                                  <h3>Become a client</h3>
                                  <p>If you don't already have a relationship with BDO, get started with a few details.</p>
                                  <button className="button primary getStarted" onClick={this.handleNextNavigationRegister.bind(this)}>Make an enquiry</button>
                              </aside>
                              <aside className="existingClient">
                                  <h3>Are you already a client?</h3>
                                  <p>If you have already registered for your online account please sign in.</p>
                                  <button className="button primary" onClick={this.handleNextNavigation.bind(this)}>Sign in</button>
                                  <p className="warning">If you have not received an invitation email, please contact your normal BDO tax team.</p>
  
  
                              </aside>
  </div>
  
  
                          </div>
  
                      </section>
                  </div>
              </article>
                  <footer className="globalFooter smallScreenFooter">
                  <div className="desktopWrapper">
                      <section className="bdoInformation">
                          <div className="legalPrivacy"><span className="copyright">Copyright © <span className="dynamicYear">{(new Date().getFullYear())}</span> BDO LLP</span>
                              <div className="flexbox">
                                        <a href="https://www.bdo.co.uk/en-gb/privacy-statement" target="_blank" rel="noopener noreferrer">Legal &amp; Privacy</a>                <a href="https://www.bdo.co.uk/en-gb/privacy-statement" target="_blank" rel="noopener noreferrer">Legal &amp; Privacy</a><a href="https://www.bdo.co.uk/en-gb/contact" title="Contact us" target="_blank" rel="noopener noreferrer">Contact us</a>
                          </div>
                          </div>
                      </section>
                      <section className="socialMedia">
                          <div className="socialItem"><a href="https://www.facebook.com/BDOLLP/" title="Facebook" target="_blank" rel="noopener noreferrer"><span className="socialIcon facebook"></span></a></div>
                          <div className="socialItem"><a href="https://twitter.com/bdoaccountant" title="X" target="_blank" rel="noopener noreferrer"><span className="socialIcon twitter"></span></a></div>
                          <div className="socialItem"><a href="https://www.linkedin.com/company/bdo-llp" title="Linkedin" target="_blank" rel="noopener noreferrer"><span className="socialIcon linkedin"></span></a></div>
                          <div className="socialItem"><a href="https://www.youtube.com/user/bdollp" title="Youtube" target="_blank" rel="noopener noreferrer"><span className="socialIcon youtube"></span></a></div>
                          <div className="socialItem"><a href="https://www.instagram.com/bdo_uk" title="Instagram" target="_blank" rel="noopener noreferrer"><span className="socialIcon instagram"></span></a></div>
                      </section>
                  </div>
              </footer>
                <CookieBanner
                  styles={styles}
                  message={message}
                  link={
                    <a
                      href="https://www.bdo.co.uk/en-gb/legal-privacy/cookies"
                      target="_blank"
                    >
                      BDO Cookie policy
                    </a>
                  }
                  onAccept={() => {}}
                  dismissOnScroll={false}
                  cookie="user-has-accepted-cookies"
                />
              </div>
            </Fragment>
          );
       
      } else {
        contentToRender = (
          <Fragment>
            <div className="App registrationPage">
              <header className="productTaskbar MSC">
                <div className="fixedWidthWrapper">
                  <section className="branding">
                    <a
                      className="homepageLink"
                      href="https://www.bdo.co.uk/en-gb/services/tax/private-client-services"
                      title="BDO Private Client Services"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Logo />
                      <h2>United Kingdom</h2>
                    </a>
                  </section>

                  <section className="globalNav">
                        <a className="mastheadLink"
                            href="https://www.bdo.co.uk/en-gb/services/tax/private-client-services" title="BDO Private Client Services"
                            target="_blank" rel="noopener noreferrer">Our services</a>
                        <a className="mastheadLink"
                            href="https://www.bdo.co.uk/en-gb/contact"
                            title="Contact us" target="_blank" rel="noopener noreferrer">Contact us</a>
                        <button title="Make and enquiry" className="mastheadLink emphasise enquiry" onClick={this.handleNextNavigationRegister.bind(this)}>Make an enquiry</button>
                        <button title="Sign in" className="mastheadLink emphasise signIn" onClick={this.handleNextNavigation.bind(this)}>Sign in</button>
                    </section>
                </div>
              </header>

              <article className="contentWrapper">
                <section className="sectionContent">
                  
                  <div className="page survey">
                    <NewCustomerRegistration
                      handleComplete={this.handleNextNavigationRegisterCancel.bind(
                        this
                      )}
                    />
                  </div>
                </section>
              </article>

              <Footer />

              <CookieBanner
                styles={styles}
                message={message}
                link={
                  <a
                    href="https://www.bdo.co.uk/en-gb/legal-privacy/cookies"
                    target="_blank"
                  >
                    BDO Cookie policy
                  </a>
                }
                onAccept={() => {}}
                dismissOnScroll={false}
                cookie="user-has-accepted-cookies"
              />
            </div>
          </Fragment>
        );
      }
    } else {
      contentToRender = (
        <Fragment>
          <section className="page browserWarning">
            <Header />
            <h2>Sorry, we are unable to support your browser.</h2>
            <h3>
              For the best experience we recommend using{" "}
              <strong>Google Chrome</strong>.
            </h3>
            <a className="button primary" href="https://www.google.com/chrome/">
              {" "}
              Download Chrome
            </a>
          </section>
        </Fragment>
      );
    }
    return <>{contentToRender}{fAQsModal}</>;
  }
}

export default withRouter(MarketingHomepage);
