import React, { Component } from "react";
import NavControlsGenerator from "../utilities/NavControlsGenerator";

class ContractAcceptance extends Component {
  componentDidMount() {
  }
  render() {
    return (
      <section className="sectionContent"><div className="page">
        <header>
          <h2>Contract Acceptance Note</h2>
        </header>
        <div className="pageContent"></div>
        <NavControlsGenerator currentPageNavCode={93}></NavControlsGenerator>
      </div></section>
    );
  }
}

export default ContractAcceptance;
