import { Configuration,  PublicClientApplication } from "@azure/msal-browser";
import { authorityB2C, clientIdB2C, forgotPasswordPolicyB2C, scopesB2C, signInPolicyB2C, webURL } from "../../api";


const config = {
  auth: {
    authority:
      //  "https://bdoukb2cdev.b2clogin.com/tfp/bdoukb2cdev.onmicrosoft.com/" +
      authorityB2C + signInPolicyB2C,
    //  clientId: "8d444452-2a92-40fa-8aff-5db73f6c376e",
    clientId: clientIdB2C,
    redirectUri: webURL + "mytaxreturns",
    postLogoutRedirectUri: webURL
  },
  cache: {
    cacheLocation: "sessionStorage"
  },
  // Uncomment to log from MSAL
  // system: {
  //   loggerOptions: {
  //     loggerCallback: (logLevel, message, containsPii) => {console.log(message)},
  //     logLevel: "verbose"
  //   }
  // }
};

export const authenticationParameters = {
  scopes: [
    // "https://bdoukb2cdev.onmicrosoft.com/personaltaxapi/user_impersonation"
    scopesB2C
  ]
};

export const forgotPasswordRequest = {
  authority: authorityB2C + forgotPasswordPolicyB2C
}

export const authProvider = new PublicClientApplication(config);
