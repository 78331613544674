import React, { Component } from "react";

import Introduction from "../questionnaire/Introduction";
import IdentityVerificationQuestions from "../cto/IdentityVerificationQuestions";
import OPTCRedFlagExit from "../cto/OPTCRedFlagExit";
import Quote from "../cto/Quote";
import VerificationConfirmation from "../cto/VerificationConfirmation";
import VerificationSpinnerNotice from "../cto/VerificationSpinnerNotice";
import FilterQuestion from "../filterquestions/FilterQuestion";
import TellUsMore from "../filterquestions/TellUsMore";
import MarketingHomepage from "../misc/MarketingHomepage";
import PageNotFound from "../misc/PageNotFound";
import Payment from "../payment/Payment";
import PaymentConfirmation from "../payment/PaymentConfirmation";
import EmailVerificationConfirmation from "../profile/EmailVerificationConfirmation";
import EmailVerificationExplanation from "../profile/EmailVerificationExplanation";
import MyTaxReturns from "../profile/MyTaxReturns";
import PasswordReset from "../profile/PasswordReset";
import YourTaxInfo from "../surveyjs/YourTaxInfo";
import AwaitingChangeNotice from "../taxreturn/AwaitingChangeNotice";
import TaxReturnApproval from "../taxreturn/TaxReturnApproval";
import ContactUs from "../misc/ContactUs";
import ProvideFurtherInformation from "../misc/ProvideFurtherInformation"
import ViewTaxReturn from "../taxreturn/ViewTaxReturn";
import ViewTransmittalLetter from "../taxreturn/ViewTransmittalLetter";
import RequestAmendments from "../taxreturn/RequestAmendments";
import VerificationTutorial from "../tutorials/VerificationTutorial";
import ScopeOfService from "../tutorials/ScopeOfService";
import TermsOfService from "../tutorials/TermsOfService";
import ContractAcceptance from "../tutorials/ContractAcceptance";
import TimeToPrepareQuote from "../tutorials/TimeToPrepareQuote";
import MedicalExpensesQuestionnaire from "../medical/MedicalExpensesQuestionnaire";

import MedicalExpenses from "../medical/MedicalExpenses";

import { Route, Switch } from "react-router-dom";
import Messages from "../messaging/Messages";

const Components = {
  Introduction: Introduction,
  IdentityVerificationQuestions: IdentityVerificationQuestions,
  OPTCRedFlagExit: OPTCRedFlagExit,
  Quote: Quote,
  VerificationConfirmation: VerificationConfirmation,
  VerificationSpinnerNotice: VerificationSpinnerNotice,
  FilterQuestion: FilterQuestion,
  TellUsMore: TellUsMore,
  MarketingHomepage: MarketingHomepage,
  PageNotFound: PageNotFound,
  Payment: Payment,
  PaymentConfirmation: PaymentConfirmation,
  EmailVerificationConfirmation: EmailVerificationConfirmation,
  EmailVerificationExplanation: EmailVerificationExplanation,
  MyTaxReturns: MyTaxReturns,
  PasswordReset: PasswordReset,
  YourTaxInfo: YourTaxInfo,
  AwaitingChangeNotice: AwaitingChangeNotice,
  TaxReturnApproval: TaxReturnApproval,
  ContactUs: ContactUs,
  ProvideFurtherInformation: ProvideFurtherInformation,
  ViewTaxReturn: ViewTaxReturn,
  ViewTransmittalLetter: ViewTransmittalLetter,
  RequestAmendments: RequestAmendments,
  VerificationTutorial: VerificationTutorial,
  ScopeOfService: ScopeOfService,
  TermsOfService: TermsOfService,
  ContractAcceptance: ContractAcceptance,
  TimeToPrepareQuote: TimeToPrepareQuote,
  Messages: Messages,
  MedicalExpenses: MedicalExpenses,
  MedicalExpensesQuestionnaire: MedicalExpensesQuestionnaire
};

class RoutesRenderer extends Component {
  render() {
    let routes;

    if (this.props.navReady) {
      routes = this.props.navigationNodes.map(node => {
        return (
          <Route
            path={node.path}
            component={Components[node.componentName]}
            key="{node.id}"
          />
        );
      });
    }

    return (
      <>
        <Switch>
          <Route
            path="/pagenotfound"
            key="pagenotfound"
            component={PageNotFound}
          />
          {routes}
        </Switch>
      </>
    );
  }
}

export default RoutesRenderer;
