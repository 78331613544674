import React, { Component } from "react";
import NavControlsGenerator from "../utilities/NavControlsGenerator";

class IdentityVerificationQuestions extends Component {
  componentDidMount() {
  }
  render() {
    return (
      <section className="sectionContent">
      <div className="page">
       <header><h2>Identity Verification Questions</h2></header> 
       <div className="pageContent"></div>
        <NavControlsGenerator currentPageNavCode={100}></NavControlsGenerator>
      </div></section>
    );
  }
}

export default IdentityVerificationQuestions;
