import React, { Component } from "react";

import Constants from "../utilities/Constants";
import SessionStoreManager from "../utilities/SessionStoreManager";
import { endpoints, httpPOST, httpGET } from "../../api";
import Auth from "../../Auth";


class PageNotFound extends Component {

  async updateNavNode(NavNode)
  {
    const auth = new Auth();
    

    let taxYearId = SessionStoreManager.getValueByKey(
      Constants.taxYearProcessKey
    ).taxYearId;
    await this.getTaxYearProcess(auth, taxYearId);

    let taxYearProcess = SessionStoreManager.getValueByKey(
      Constants.taxYearProcessKey
    );

    taxYearProcess.currentNavNodeCode = NavNode;
    await this.updateDB(auth, taxYearProcess);

    window.location.replace("/mytaxreturns");
  }

  async getTaxYearProcess(auth, taxYearId) {
    var currentUser = SessionStoreManager.getValueByKey(
      Constants.currentUserCosmosDBKey
    );
    var parameters = currentUser.id + "/" + taxYearId;

    let response = await httpGET(
      endpoints.filter((endpoint) => endpoint.name === "GetTaxYearProcess")[0],
      parameters,
      await auth.getToken(),
      true
    );

    if (response && response.status === 200) {
      SessionStoreManager.setValueForKey(
        Constants.taxYearProcessKey,
        response.body
      );
    }
  }


  async updateDB(auth, taxYearObj) {
    let response = await httpPOST(
      endpoints.filter(
        endpoint => endpoint.name === "GetOrCreateTaxYearProcess"
      )[0],
      await auth.getToken(),
      taxYearObj,
      true
    );
    if (response && response.status === 200) {
      // Update cache once DB was successfully updated
      SessionStoreManager.setValueForKey(
        Constants.taxYearProcessKey,
        taxYearObj
      );
    }
  }

  render() {
    return <section className="sectionContent"><div className="page errorPage">
      <div className="desktopWrapper"><h2>404</h2>
    <h3>That page does not exist or is unavailable</h3>
    <button onClick={() => this.updateNavNode(140)} className="button">Go to your tax returns</button></div>
    </div></section>;
  }
}

export default PageNotFound;
