import React, { Component } from "react";
import Constants from "../utilities/Constants";
import SessionStoreManager from "../utilities/SessionStoreManager";


import { withRouter } from "react-router-dom";
import Auth from "../../Auth";
import { endpoints, httpPOST } from "../../api";

class NavControlsGenerator extends Component {
  async updateTaxYearAndRedirect(navNode, navRule) {
    const auth = new Auth();

    // Check if authentication token is till valid and redirect to login page is it isn't
    /*  if (!auth.isTokenValid()) {
      auth.logout();
    }*/
    var result;

    if (this.props.prepareForNavigation) {
      if (Array.isArray(this.props.prepareForNavigation)) {
        for (var i = 0; i < this.props.prepareForNavigation.length; i++) {
          if (this.props.prepareForNavigation[i]) {
            if (
              this.props.prepareForNavigation[i][0] ===
              navRule.prepareForNavigationFuncName
            ) {
              result = await this.props.prepareForNavigation[i][1]();

              // Prepare for navigation function may return false which indicates that navigation must be cancelled
              if (result !== undefined) {
                if (!result) {
                  //console.log("cancelling navigation");
                  return;
                } else {
                  // console.log("continue with navigation");
                }
              } else {
                //  console.log("result undefined");
              }
            }
          }
        }
      } else {
        result = await this.props.prepareForNavigation();

        // Prepare for navigation function may return false which indicates that navigation must be cancelled
        if (result !== undefined) {
          if (!result) {
            //  console.log("cancelling navigation");
            return;
          } else {
            //  console.log("continue with navigation");
          }
        } else {
          //  console.log("result undefined");
        }
      }
    }

    //console.log("NavControlsGenerator - updating Nav info");
    // Get taxyearprocess object from cache
    let taxYearProcess = SessionStoreManager.getValueByKey(
      Constants.taxYearProcessKey
    );

    taxYearProcess.currentNavNodeCode = navNode.code;
   // console.log("Tax Year Process: " + JSON.stringify(taxYearProcess));

    await this.updateDB(auth, taxYearProcess);

    this.props.history.push(navNode.path);
  }

  async updateDB(auth, taxYearObj) {
    
    let response = await httpPOST(
      endpoints.filter(
        endpoint => endpoint.name === "GetOrCreateTaxYearProcess"
      )[0],
      await auth.getToken(),
      taxYearObj,
      true
    );
     
    if (response && response.status === 200) {
      if(response.body.reviewStatus==null || typeof(response.body.reviewStatus)=='undefined'){response.body.reviewStatus='';}
        if(response.body.pathToTaxReturnBlob==null || typeof(response.body.pathToTaxReturnBlob)=='undefined'){response.body.pathToTaxReturnBlob='';}
        if(response.body.pathToTransmittalLetterBlob==null || typeof(response.body.pathToTransmittalLetterBlob)=='undefined'){response.body.pathToTransmittalLetterBlob='';}
        if(response.body.pathToPaymentBlob==null || typeof(response.body.pathToPaymentBlob)=='undefined'){response.body.pathToPaymentBlob='';}
        if(response.body.pathToSmartSearchBlob==null || typeof(response.body.pathToSmartSearchBlob)=='undefined'){response.body.pathToSmartSearchBlob='';}
        if(response.body.ctoStatus==null || typeof(response.body.ctoStatus)=='undefined'){response.body.ctoStatus='';}
        if(response.body.questionnaireStarted==null || typeof(response.body.questionnaireStarted)=='undefined'){response.body.questionnaireStarted='';}
        if(response.body.hmrcSubmissionReference==null || typeof(response.body.hmrcSubmissionReference)=='undefined'){response.body.hmrcSubmissionReference='';}
        if(response.body.hmrcSubmissionDate==null || typeof(response.body.hmrcSubmissionDate)=='undefined'){response.body.hmrcSubmissionDate='';}
        if(response.body.rpaComments==null || typeof(response.body.rpaComments)=='undefined'){response.body.rpaComments='';} 
       
      // Update cache once DB was successfully updated
      SessionStoreManager.setValueForKey(
        Constants.taxYearProcessKey,
        taxYearObj
      );

      // console.log("getOrCreateTaxYearProcess: " + JSON.stringify(taxYearObj));
      // console.log("getOrCreateTaxYearProcess response: " + response);
    }
  }

  render() {
    let controls;

    if (
      SessionStoreManager.getValueByKey(Constants.currentUserCosmosDBKey) &&
      SessionStoreManager.getValueByKey(Constants.navRulesKey) &&
      SessionStoreManager.getValueByKey(Constants.navNodesKey) &&
      SessionStoreManager.getValueByKey(Constants.taxYearProcessKey)
    ) {
      // From all navigation rules select rules  where  NavNodeCodeFrom equals current page nav code (this.props.currentPageNavCode) and user CustomerTypeId
      // equals customer type of the currently logged on user (currentUserCosmosDBKey)
      let navigationRules = SessionStoreManager.getValueByKey(
        Constants.navRulesKey
      ).filter(
        navRule =>
          navRule.navNodeCodeFrom === this.props.currentPageNavCode &&
          navRule.customerTypeId ===
            SessionStoreManager.getValueByKey(Constants.currentUserCosmosDBKey)
              .customerTypeId
      );

      controls = navigationRules.map(navRule => {
        // Render navigation controls only if transition to the next node exists according to the rule
        if (navRule.navNodeCodeTo) {
          // Find nav node and get button title from it
          let navNode = SessionStoreManager.getValueByKey(
            Constants.navNodesKey
          ).filter(navNode => navNode.code === navRule.navNodeCodeTo)[0];

          // Add event handler
          return (
            <React.Fragment key={navRule.id}>
              <button
                className="button"
                onClick={() => {
                  this.updateTaxYearAndRedirect(navNode, navRule);
                }}
              >
                {navRule.navElTitle}
              </button>
            </React.Fragment>
          );
        }
      });
    } else {
      /*console.log(
        "Can't generate navigation because some of the caches are empty. Check NavControlsGenerator component"
      );*/
    }

    return <footer className="page-footer">{controls}</footer>;
  }
}

export default withRouter(NavControlsGenerator);
