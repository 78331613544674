class SessionStoreManager {
  static getValueByKey(key) {
    let cachedValue = sessionStorage.getItem(key);

    if (cachedValue !== "undefined" && cachedValue !== null) {
      /* console.log(
        "SessionStoreManager: getValueByKey " + key + ": " + cachedValue
      );*/
      return JSON.parse(cachedValue);
    } else return null;
  }

  static getValueByKeyAsString(key) {
    let cachedValue = sessionStorage.getItem(key);

    if (cachedValue !== "undefined" && cachedValue !== null) {
      /* console.log(
        "SessionStoreManager: getValueByKey " + key + ": " + cachedValue
      );*/
      return cachedValue;
    } else return null;
  }

  static setValueForKey(key, value) {
    /* console.log(
      "SessionStoreManager: setValueForKey: " +
        key +
        " " +
        JSON.stringify(value)
    );*/

    sessionStorage.setItem(key, JSON.stringify(value));
  }

  static clearAllKeys() {
    /* console.log(
      "SessionStoreManager: setValueForKey: " +
        key +
        " " +
        JSON.stringify(value)
    );*/

    sessionStorage.clear();
  }
}

export default SessionStoreManager;
